/* eslint-disable semi */
import firebase from 'firebase/compat/app';
// Add the Firebase products that you want to use
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

import Auth from './firestore/auth';
import BusinessPoint from './firestore/business-point';
import Product from './firestore/product';
import Planogram from './firestore/planogram';
import Transaction from './firestore/transaction';
import Transactionrfid from './firestore/transactionrfid';
import Snapshot from './firestore/snapshot';
import ProductStorage from './storage/product';
import Ingredient from './firestore/ingredient';
import Distributor from './firestore/distributor';
import Refiiler from './firestore/refiller';
import SerialNumber from './firestore/serialnumber';
import Alert from './firestore/alert';
import AlertDevice from './firestore/alertdevice';
import AlertExplanation from './firestore/alertExplanation';
import Stock from './firestore/stock';
import Role from './firestore/role';
import Payout from './firestore/payout';
import Balance from './firestore/balance';
import HistoryPlanogram from './firestore/planogram_history'
import Refund from './firestore/refund'
import BpFee from "./firestore/bpfee"
import SnapshotDaily from "./firestore/snapshot-daily"
import SnapshotMonthly from "./firestore/snapshot-monthly"
class FirebaseHelper {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);
      firebase.auth().onAuthStateChanged(user => {
        // console.log(user)

        // if (user) {
        //   localStorage.setItem('authUser', JSON.stringify(user));
        // } else {
        //   localStorage.removeItem('authUser');
        // }
      });
    }

    // Firestore query
    this.bpfee = new BpFee(firebase)
    this.auth = new Auth(firebase);
    this.businessPoint = new BusinessPoint(firebase);
    this.product = new Product(firebase);
    this.planogram = new Planogram(firebase);
    this.transaction = new Transaction(firebase);
    this.transactionrfid = new Transactionrfid(firebase);
    this.snapshot = new Snapshot(firebase);
    this.ingredient = new Ingredient(firebase);
    this.distributor = new Distributor(firebase);
    this.refiller = new Refiiler(firebase);
    this.alert = new Alert(firebase);
    this.serialnumber = new SerialNumber(firebase);
    this.alertdevice = new AlertDevice(firebase);
    this.alertExplanation = new AlertExplanation(firebase);
    this.stock = new Stock(firebase);
    this.role = new Role(firebase);
    this.payout = new Payout(firebase);
    this.balance = new Balance(firebase);
    this.historyPlanogram = new HistoryPlanogram(firebase);
    this.refund = new Refund(firebase);
    this.snapshotDaily = new SnapshotDaily(firebase)
    this.snapshotMonthly = new SnapshotMonthly(firebase)
    // Firebase Storage
    this.productStorage = new ProductStorage(firebase);
  }

  /**
   * Registers the user with given details
   */
  registerUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser);
          },
          error => {
            reject(this._handleError(error));
          }
        );
    });
  };
  registerRole = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve({ code: 0, body: firebase.auth().currentUser });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };

  /**
   * Registers the user with given details
   */
  editProfileAPI = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser);
          },
          error => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser);
          },
          error => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * forget Password user with given details
   */
  forgetPassword = email => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email, {
          url:
            window.location.protocol + '//' + window.location.host + '/login',
        })
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          reject(this._handleError(error));
        });
    });
  };

  // BUtuh Firebase Functions
  deleteUser = id => {
    return new Promise((resolve, reject) => {
      firebase.auth().getUser(uid).delete();
    });
  };

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Social Login user with given details
   */
  socialLoginUser = (data, type) => {
    let credential = {};
    if (type === 'google') {
      credential = firebase.auth.GoogleAuthProvider.credential(
        data.idToken,
        data.token
      );
    } else if (type === 'facebook') {
      credential = firebase.auth.FacebookAuthProvider.credential(data.token);
    }
    return new Promise((resolve, reject) => {
      if (!!credential) {
        firebase
          .auth()
          .signInWithCredential(credential)
          .then(user => {
            resolve(this.addNewUserToFirestore(user));
          })
          .catch(error => {
            reject(this._handleError(error));
          });
      } else {
        reject(this._handleError(error));
      }
    });
  };

  addNewUserToFirestore = user => {
    const collection = firebase.firestore().collection('users');
    const { profile } = user.additionalUserInfo;
    const details = {
      firstName: profile.given_name ? profile.given_name : profile.first_name,
      lastName: profile.family_name ? profile.family_name : profile.last_name,
      fullName: profile.name,
      email: profile.email,
      picture: profile.picture,
      createdDtm: firebase.firestore.FieldValue.serverTimestamp(),
      lastLoginTime: firebase.firestore.FieldValue.serverTimestamp(),
    };
    collection.doc(firebase.auth().currentUser.uid).set(details);
    return { user, details };
  };

  // setLoggeedInUser = user => {
  //   localStorage.setItem('authUser', JSON.stringify(user));
  // };

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!localStorage.getItem('authUser')) return null;
    return JSON.parse(localStorage.getItem('authUser'));
  };

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message;
    return errorMessage;
  }
}

let _firebaseInstance = null;

/**
 * Initilize the instance
 * @param {*} config
 */
const init = config => {
  if (!_firebaseInstance) {
    _firebaseInstance = new FirebaseHelper(config);
  }
  return _firebaseInstance;
};

/**
 * Returns the firebase instance
 */
const getInstance = () => {
  return _firebaseInstance;
};

export { init, getInstance };
