/* eslint-disable semi */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, CardBody, Alert } from 'reactstrap';

import { connect } from 'react-redux';
// Iotera
import { safeDeepGet } from 'iotera-base/utility/json';

import {
  get_vm_status,
  get_role,
  get_notification,
} from '../../store/dashboard/actions';

// Pages Components
import Earning from './earning';
import DashboardMap from './dashboardmap';
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Pages403 from 'Base/pages/pages-403';
import { APPLICATION_ID, NAME_PROJECT, buildTitle, getAvatarTheme } from 'Apps';
import { NAVBAR, PERMISION } from 'Apps';
import Result from 'iotera-base/result';
const navbar = NAVBAR;
const permision = PERMISION;
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reports: [
        {
          title: 'VM Terdaftar',
          iconClass: 'bx-copy-alt',
          bgClass: 'bg-primary',
          description: '',
        },
        {
          title: 'VM Nyala',
          iconClass: 'bx-archive-in',
          bgClass: 'bg-success',
          description: '',
        },
        {
          title: 'VM Mati',
          iconClass: 'bx-purchase-tag-alt',
          bgClass: 'bg-secondary',
          description: '',
        },
        {
          title: 'Jumlah alert',
          iconClass: 'bx-copy-alt',
          bgClass: 'bg-warning',
          description: '',
        },
      ],
      chartSeries: [],
      periodType: 'yearly',
    };
  }

  componentDidMount() {
    const userData = localStorage.getItem('email');
    this.props.GetVmStatus();
    this.props.GetNotification();
    this.props.GetRoleUser(userData);
  }

  componentDidUpdate(prevProps) {
    if (this.props.notificationResult !== prevProps.notificationResult) {
      // Success create
      const code = safeDeepGet(
        this.props,
        ['notificationResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code === Result.SUCCESS) {
        const notificationData = safeDeepGet(
          this.props,
          ['notificationResult', 'body'],
          {}
        );
       
        const show = safeDeepGet(notificationData, 'show', false);
        const message = safeDeepGet(notificationData, 'message', '');
        this.setState({show, message})
        // this.showToast();
        // setTimeout(window.location.reload(), 1000);
        // this.toggle();
      } else if (code === Result.FAILED) {
        this.showToastFailed();
      }
    }
  }

  render() {
    const vm_status = safeDeepGet(this.props, ['vmstatusresult', 'body'], {});
    const {show, message} = this.state;

    const vm_card = safeDeepGet(vm_status, ['card'], []);
    const vm_map = safeDeepGet(vm_status, ['map'], []);
    const data = safeDeepGet(vm_status, ['card'], '');

    return (
      <React.Fragment>
        <div className="page-content">
          {show ? (
            <div className="alert-container">
              <Alert color="danger">
                {message}
              </Alert>
            </div>
          ) : (
            <></>
          )}

          <MetaTags>
            <title> {buildTitle('Dashboard')}</title>
          </MetaTags>
          {!permision ||
          safeDeepGet(navbar, ['Overview', 'view'], 'off') == 'off' ? (
            <Pages403 />
          ) : (
            <Container fluid>
              {/* Render Breadcrumb */}
              <Breadcrumbs title="Dashboards" breadcrumbItem="Overview" />
              <Row>
                <Col xl="12">
                  <Row>
                    {/* Reports Render */}
                    {this.state.reports.map((report, key) => (
                      <Col md="3" key={'_col_' + key}>
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                  {report.title}
                                </p>
                                <h4 className="mb-0">{vm_card[key]}</h4>
                              </div>
                              <div
                                className={
                                  'mini-stat-icon avatar-sm rounded-circle ' +
                                  report.bgClass +
                                  ' align-self-center'
                                }
                              >
                                <span className={getAvatarTheme()}>
                                  <i
                                    className={
                                      'bx ' + report.iconClass + ' font-size-24'
                                    }
                                  />
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>

                  <Row className="clearfix">
                    {/* earning */}
                    <Earning />
                    {/* map */}
                    <Col xl="5" className="d-flex">
                      <Card className="flex-fill">
                        <CardBody>
                          <h4 className="card-title mb-4">Peta VM</h4>
                          <p>Biru = VM mati, hijau = VM nyala</p>
                          <div id="leaflet-map" className="leaflet-map">
                            <DashboardMap vm_maps={vm_map} />
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  vmstatusresult: safeDeepGet(state, ['dashboard', 'vm_status'], {}),
  notificationResult: safeDeepGet(state, ['dashboard', 'get_notification'], {}),
  loading: safeDeepGet(state, ['dashboard', 'loading'], true),
});

const mapDispatchToProps = dispatch => ({
  GetVmStatus: () => dispatch(get_vm_status()),
  GetNotification: () => dispatch(get_notification()),
  GetRoleUser: payload => dispatch(get_role(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
