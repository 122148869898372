/* eslint-disable semi */
import {
    LOADING,
    RESULT,
    LIST_BUSINESS_POINTS,
    LIST,
    NEXT_LIST,
    READ,
    REFUND,
    REFUNDED,
    UPDATE_REFUND,
    SUMMARY,
    SNAPSHOT,DETAIL,
    REFUND_LIST,
    GET_SALES_ANALYTICS,
    SNAPSHOT_DAILY,
    SNAPSHOT_MONTHLY,
    GET_REPORT_GROUP,
    DOWNLOAD_DATA,
    SEARCH_DATA,
    UPDATE_DOWNLOAD_PROGRESS
} from "./actionTypes";

export const refunded = payload => ({
    type: REFUNDED,
    payload,
  });

export const loading = (actionType) => ({
    type: LOADING,
    payload: { actionType },
});
export const detail_transaction = payload => ({
    type:DETAIL,
    payload
})
export const search_data = payload => ({
    type:SEARCH_DATA,
    payload
})
export const result = (actionType, result) => ({
    type: RESULT,
    payload: { actionType, result },
});

export const list_business_points = () => ({
    type: LIST_BUSINESS_POINTS,
})
export const get_report_group = payload => ({
    type: GET_REPORT_GROUP,
    payload
})
export const refund_list = payload => ({
    type: REFUND_LIST,
    payload
})
export const download_data = payload => ({
    type: DOWNLOAD_DATA,
    dateRange: payload.dateRange,
    transactionStatus: payload.transactionStatus,
    dispenseStatus: payload.dispenseStatus,
    businessPoints: payload.businessPoints,
    name: payload.name,
    bank: payload.bank,
    cost: payload.cost,
    tags: payload.tags
})

export const updateDownloadProgress = (progress) => ({
    type: UPDATE_DOWNLOAD_PROGRESS,
    payload: progress,
  });

export const list = (payload) => ({
    type: LIST,
    dateRange: payload.dateRange,
    transactionStatus: payload.transactionStatus,
    dispenseStatus: payload.dispenseStatus,
    businessPoints: payload.businessPoints,
    bank:   payload.bank,
    tags: payload.tags
});

export const nextList = (payload) => ({
    type: NEXT_LIST,
    dateRange: payload.dateRange,
    transactionStatus: payload.transactionStatus,
    dispenseStatus: payload.dispenseStatus,
    businessPoints: payload.businessPoints,
    fistDocs: payload.fistDocs,
    typeButton: payload.typeButton,
    lastDocs: payload.lastDocs,
    lastDocsPrev: payload.lastDocsPrev,
    page: payload.page,
    bank:   payload.bank,
    arr_timeStamp: payload.arr_timeStamp,
    tags: payload.tags

});

export const read = (transactionId) => ({
    type: READ,
    payload: { transactionId },
});

export const updateRefund = payload => ({
    type: UPDATE_REFUND,
    payload,
  });
  
export const refund = (transactionId) => ({
    type: REFUND,
    payload: { transactionId },
});

export const summary = (payload) => ({
    type: SUMMARY,
    businessPoints: payload.businessPoints,
    month: payload.month,
    year: payload.year,
    date: payload.dateRange
});

export const snapshot = (payload) => ({
    type: SNAPSHOT,
    businessPoints: payload.businessPoints,
    month: payload.month,
    year: payload.year,
    date: payload.dateRange

});

export const get_sales_analytics = (payload) => ({
    type: GET_SALES_ANALYTICS,
    businessPoints: payload.businessPoints,
    month: payload.month,
    year: payload.year,
    date: payload.dateRange
});
export const get_snapshot_daily = (payload) => ({
    type: SNAPSHOT_DAILY,
    businessPoints: payload.businessPoints,
    month: payload.month,
    year: payload.year,
    bank: payload.bank,
    date: payload.dateRange
});
export const get_snapshot_monthly = (payload) => ({
    type: SNAPSHOT_MONTHLY,
    payload
});