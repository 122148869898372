import {
    LOADING,
    RESULT,
    GET_VM_STATUS,
    GET_NETT_SETTLEMENT,
    GET_SNAPSHOT,
    GET_ROLE,
    GET_NOTIFICATION
} from "./actionTypes";

export const loading = (actionType) => ({
    type: LOADING,
    payload: { actionType },
});

export const result = (actionType, result) => ({
    type: RESULT,
    payload: { actionType, result },
});

// VM status data - 4 cards di atas
export const get_vm_status = () => ({
    type: GET_VM_STATUS,
});
export const get_notification = () => ({
    type: GET_NOTIFICATION,
});

// charts data
// export const get_nett_settlement = (payload) => ({
//     type: GET_NETT_SETTLEMENT,
//     businessPoints: payload.businessPoints,
//     month: payload.month,
//     year: payload.year,
//     bank: payload.bank,
//     date: payload.dateRange
// });

export const get_snapshot = (payload) => ({
    type: GET_SNAPSHOT,
    payload
});
export const get_role = (payload) => ({
    type: GET_ROLE,
    payload
});

