/* eslint-disable semi */
import { call, put, takeEvery } from 'redux-saga/effects';

import { LIST, INIT_CREATE, CREATE, DELETE, LIST_TAGS } from './actionTypes';
import { getTags, createTags, deleteTags } from 'Apps/device';
import { loading, result } from './actions';
import { getInstance } from '../../firebase/helper';
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json';
import Result from 'iotera-base/result';
// import { createdUser, deletedUser } from 'Apps/device';
const firebaseInstance = getInstance();

function* list() {
  yield put(loading(LIST));
  const wsResult = yield call(getTags);
  if (safeDeepGet(wsResult, 'code') == Result.HTTP.OK) {
    const bodyTags = safeDeepGet(wsResult, ['body', 'tags']);
    yield put(result(LIST, { code: Result.HTTP.OK, body: bodyTags }));
    return;
  }
  yield put(result(LIST, { result: { code: Result.FAILED } }));
}

function* initCreate() {
  yield put(loading(INIT_CREATE));
  let wsResult = yield call(firebaseInstance.navigation.list);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  if (code == Result.SUCCESS) {
    const dataBody = safeDeepGet(wsResult, ['body', 'data'], {});
    const dataList = [];
    const bpList = [];
    let i = 1;
    for (const data in dataBody) {
      const edit = safeDeepGet(dataBody, [data, 'edit'], 'off');
      const view = safeDeepGet(dataBody, [data, 'view'], 'off');
      let access = 'No_access';
      if (view == 'on' && edit == 'on') {
        access = 'Edit';
      } else if (view == 'on' && edit == 'off') {
        access = 'View';
      }
      safeDeepSet(dataBody, [data, 'access'], access);
      safeDeepSet(dataBody, [data, 'name'], data);
      safeDeepSet(dataBody, [data, 'id'], i++);
      dataList.push(dataBody[data]);
    }
    wsResult = yield call(firebaseInstance.businessPoint.list);
    const code = safeDeepGet(wsResult, 'code', Result.FAILED);
    if (code == Result.SUCCESS) {
      const bpBody = safeDeepGet(wsResult, 'body', {});
      for (const bp in bpBody) {
        const name = safeDeepGet(bpBody, [bp, 'name'], '');
        const device_id = safeDeepGet(bpBody, [bp, 'device_id'], '');
        bpList.push({ name, device_id, active: 'on' });
      }
      yield put(
        result(INIT_CREATE, {
          code: Result.SUCCESS,
          body: dataList,
          bpList: bpList,
        })
      );
    } else {
      yield put(result(INIT_CREATE, { code: Result.FAILED, body: [] }));
    }
  } else {
    yield put(result(INIT_CREATE, { code: Result.FAILED, body: [] }));
  }
}
function* create(action) {
  yield put(loading(CREATE));
  const payload = safeDeepGet(action, 'payload');
  const wsResult = yield call(createTags, payload);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  yield put(result(CREATE, { result: { code } }));
}

function* list_tags() {
  yield put(loading(LIST_TAGS));
  const role = JSON.parse(localStorage.getItem('role'));
  var access = yield call(firebaseInstance.role.getAccess, role);
  const allBp = safeDeepGet(access, ['body', 'allBp'], 'false');
  const accessBP = safeDeepGet(access, ['body', 'businessPoint'], []);
  const arr_bp = [];
  for (const bp in accessBP) {
    const activeBp = safeDeepGet(accessBP, [bp, 'active'], 'off');
    if (activeBp == 'on') {
      arr_bp.push(safeDeepGet(accessBP, [bp, 'device_id']));
    }
  }
  const arr_tags = [];
  let wsResult = yield call(firebaseInstance.businessPoint.list);
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const businessPointMap = wsResult.body;
    for (const deviceId in businessPointMap) {
      const tag = safeDeepGet(businessPointMap, [deviceId, 'tags']);
      if (allBp == 'true') {
        if (!arr_tags.includes(tag)) {
          arr_tags.push(tag);
        }
      } else if (allBp == 'false') {
        if (arr_bp.includes(deviceId)) {
          if (!arr_tags.includes(tag)) {
            arr_tags.push(tag);
          }
        }
      }
    }
  }
  wsResult = yield call(getTags);
  if (safeDeepGet(wsResult, 'code') == Result.HTTP.OK) {
    const listMap = safeDeepGet(wsResult, ['body', 'tags'], {});
    const tagsList = [{ label: 'No Tags', value: '' }];
    for (const id in listMap) {
      let tagsName = safeDeepGet(listMap[id], 'name', '-');
      let tagsKey = safeDeepGet(listMap[id], 'key', '-');
      if (allBp == 'true') {
        if (arr_tags.includes(tagsKey)) {
          tagsList.push({ label: tagsName, value: tagsKey });
        }
      } else if (allBp == 'false') {
        if (arr_tags.includes(tagsKey)) {
          tagsList.push({ label: tagsName, value: tagsKey });
        }
      }
    }
    yield put(result(LIST_TAGS, { code: Result.SUCCESS, body: tagsList }));
    return;
  }
  yield put(result(LIST_TAGS, { result: { code: Result.FAILED } }));
}

function* delete_(payload) {
  yield put(loading(DELETE));
  const id = safeDeepGet(payload, ['payload', 'id']);
  const body = {
    id,
  };
  const wsResult = yield call(deleteTags, body);
  const code = safeDeepGet(wsResult, 'code', Result.FAILED);
  yield put(result(DELETE, { result: { code } }));
}

function* productSaga() {
  yield takeEvery(LIST, list);
  yield takeEvery(INIT_CREATE, initCreate);
  yield takeEvery(CREATE, create);
  yield takeEvery(DELETE, delete_);
  yield takeEvery(LIST_TAGS, list_tags);
}

export default productSaga;
