export const LOADING = "LOADING";
export const RESULT = "RESULT";

export const LIST = "ACCOUNT_BANK_LIST";
export const PAYOUT_LIST = "PAYOUT_ADMIN_LIST";
export const PAYOUT_VALIDATE = "PAYOUT_ADMIN_VALIDATE";
export const PAYOUT_REQUEST = "PAYOUT_ADMIN_REQUEST";
export const PAYOUT_CREATE = "PAYOUT_ADMIN_CREATE";
export const PAYOUT_CANCEL= "PAYOUT_ADMIN_CANCEL";
export const ACCOUNT_LIST = "ACCOUNT_LIST";
export const INIT_CREATE = "PAYOUT_ADMIN_INIT_CREATE";
export const CREATE = "BANK_CREATE";
export const READ = "PAYOUT_ADMIN_INFO";
export const UPDATE = "PAYOUT_ADMIN_UPDATE";
export const STOCK_UPDATE = "STOCK_PAYOUT_UPDATE";
export const DELETE = "PAYOUT_ADMIN_DELETE";
export const DETAIL_ACCOUNT = "DETAIL_ACCOUNT";
export const GET_BALANCE = "GET_BALANCE"
export const GET_PAYOUT_BALANCE = "GET_PAYOUT_BALANCE"
export const POST_USER_DATA = "POST_USER_DATA"
