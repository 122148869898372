/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable react/prop-types */
import FailedAnimation from 'Base/assets/FailedAnimation';
import React, { Component } from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Input,
  Row,
  Col,
  ModalFooter,
} from 'reactstrap';

class WarningModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: new Array(6).fill(''), // Inisialisasi OTP dengan array kosong
      resend: 0,
    };
  }
  render() {
    const { isOpen, toggle } = this.props;
    const { resend } = this.state;
    const phoneNumber = localStorage.getItem('phoneNumber');
    const email = localStorage.getItem('email');
    return (
      <Modal size='lg' isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody>
          <div className="text-center mb-3">
            <FailedAnimation />
            <h5>
              Nomor Telepon tidak terdaftar, mohon isikan terlebih dahulu pada
              menu
              <b> Management </b> → <b> Account </b> →{' '}
              <b>Account yang Dipakai</b>.
            </h5>
            <p className="mt-3">
              Jika sudah menambahkan, tapi masih tidak bisa, coba <b>logout</b>{' '}
              dan login kembali.
            </p>
            <p className="mt-2">
              Jika menu <b>Management</b> tidak tersedia, harap hubungi{' '}
              <b>admin</b> untuk bantuan lebih lanjut.
            </p>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default WarningModal;
