/* eslint-disable semi */
import { takeEvery, put, call, takeLatest, delay } from 'redux-saga/effects';

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from './actionTypes';
import { loginSuccess, logoutUserSuccess, apiError } from './actions';

//Include Both Helper File with needed methods
import { getInstance } from '../../../firebase/helper';
import { postSocialLogin } from '../../../helpers/fakebackend_helper';

import { getAuth, signInWithCustomToken, signOut } from 'firebase/auth';

const firebaseInstance = getInstance();
import { loginFirebase } from 'Apps/device';
import Result from 'iotera-base/result';
import { safeDeepGet } from 'iotera-base/utility/json';
import { APPLICATION_ID } from 'Apps';
const auth = getAuth();

function* loginUser({ payload: { user, history } }) {
  try {
    let email = user.email.toLowerCase();

    const body = {
      email: user.email.toLowerCase(),
      password: user.password,
      vm_code: APPLICATION_ID.substring(APPLICATION_ID.length - 4),
    };

    const pp = btoa(user.password)
    localStorage.setItem("pp", pp)
    // const ww = atob(pp);
    console.log(body)

    const responseAP = yield call(loginFirebase, body);
    const code = safeDeepGet(responseAP, 'code', Result.UNKNOWN_ERROR);
    if (code == Result.HTTP.OK) {
      const status_body = safeDeepGet(responseAP, ['body', 'status_body'], '');
      const responseBody = safeDeepGet(
        responseAP,
        ['body', 'body'],
        safeDeepGet(responseAP, 'body', {})
      );
      const response = { ...responseBody, ...status_body };
      const authToken = safeDeepGet(responseAP, ['body', 'token'], null);
      if (authToken) {
        yield call(signInWithCustomToken, auth, authToken);

        localStorage.setItem('email', user.email);
        localStorage.setItem('authUser', JSON.stringify(response));
        yield delay(3000);
        var data = yield call(firebaseInstance.role.list, user.email);
        const role = data.body.role;
        if (role == 'distributor') {
          history.push('/business-point/list');
        } else if (role == 'refiller') {
          history.push('/business-point/list');
        } else {
          history.push('/dashboard');
          window.location.reload();
        }
      } else {
        const error = safeDeepGet(
          responseAP,
          ['body', 'message'],
          'Failed to Get Account'
        );
        yield put(apiError(error));
      }
    } else {
      const error = safeDeepGet(
        responseAP,
        ['body', 'message'],
        'Username atau password salah, atau account tidak Terdaftar'
      );
      yield put(apiError(error));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    signOut(auth)
      .then(() => {
        localStorage.removeItem('authUser');
        localStorage.removeItem('role');
        localStorage.removeItem('pp')
        history.push('/login');
        location.reload();
      })
      .catch(error => {
        console.log(error);
      });
    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      const response = yield call(firebaseInstance.logout);
      yield put(logoutUserSuccess(response));
    }
    history.push('/login')
  } catch (error) {
    yield put(apiError(error));
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      const response = yield call(firebaseInstance.socialLoginUser, data, type);
      localStorage.setItem('authUser', JSON.stringify(response));
      yield put(loginSuccess(response));
    } else {
      const response = yield call(postSocialLogin, data);
      localStorage.setItem('authUser', JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    history.push('/dashboard');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
