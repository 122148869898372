/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable no-dupe-keys */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import Select from 'react-select';
import { Link } from 'react-router-dom';
// Strap
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Container,
  Label,
  Input,
  Table,
} from 'reactstrap';
// Table
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import {
  list_product,
  deletePlanogram,
} from '../../../SvNestleMilano/store/business-point/actions';
import { read } from '../../../SvNestleMilano/store/product/actions';
// Breadcrumbs
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Result from 'iotera-base/result';

// Iotera
import { safeDeepGet } from 'iotera-base/utility/json';
// Redux
import { NAME_PROJECT, buildTitle, APPLICATION_ID } from 'Apps';
import { connect } from 'react-redux';
import {
  readPlanogramMilano,
  updatePlanogramMilano,
} from '../../store/business-point/actions';
let buildColumns = [];
let buildPlanogramList = {};
//SweetAlert
import SweetAlert from 'react-bootstrap-sweetalert';

import ReactExport from 'react-data-export';
import { CircularProgress } from '@material-ui/core';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class PlanogramNestle extends Component {
  constructor(props) {
    super(props);
    let isEdit = false;
    const { data, type } = props;
    var deviceId;
    if (APPLICATION_ID == '1000000159') {
      deviceId = safeDeepGet(this.props.match.params, 'id');
    } else {
      deviceId = data;
    }

    const deviceType = type;
    this.state = {};
    this.handleChangeProduct = this.handleChangeProduct.bind(this);
    // this.handleFormChange = this.handleFormChange.bind(this);
    this.handleChangeOrder = this.handleChangeOrder.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showToast = this.showToast.bind(this);
    this.showAdd = this.showAdd.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.showDelete = this.showDelete.bind(this);
    buildColumns = productMap => {
      const productList = [];
      for (const sku in productMap) {
        productList.push({
          label: productMap[sku],
          value: sku,
        });
      }
      return [
        {
          dataField: 'selection',
          text: 'Selection',
          editable: false,
        },
        {
          dataField: 'sku',
          text: 'Product',
          editor: {
            type: Type.SELECT,
            getOptions: (setOptions, { row, column }) => {
              return productList;
            },
          },
          formatter: (cell, row, rowIndex, productMap) => (
            <>
              <p>{safeDeepGet(productMap, row.sku, '')}</p>
            </>
          ),

          formatExtraData: productMap,
          style: (cell, row, rowIndex, colIndex, productMap) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
          editCellStyle: (cell, row, rowIndex, colIndex) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
        },
        {
          dataField: 'price',
          text: 'Price (Rp)',
          validator: (newValue, row, column) => {
            if (!/^\d+$/.test(newValue)) {
              return {
                valid: false,
              };
            }
            return true;
          },
        },
      ];
    };
    const buildPlanogram = (id, planogramMap) => {
      const selection = safeDeepGet(planogramMap[id], 'selection');
      const sku = safeDeepGet(planogramMap[id], 'sku');
      const display_name = safeDeepGet(planogramMap[id], 'display_name');
      const price = safeDeepGet(planogramMap[id], 'price');
      return {
        key: selection,
        selection,
        sku,
        price,
        display_name,
      };
    };
    buildPlanogramList = planogramMap => {
      const planogramList = [];
      planogramList.push(buildPlanogram('0', planogramMap));
      planogramList.push(buildPlanogram('1', planogramMap));
      planogramList.push(buildPlanogram('2', planogramMap));
      planogramList.push(buildPlanogram('3', planogramMap));
      planogramList.push(buildPlanogram('4', planogramMap));
      planogramList.push(buildPlanogram('5', planogramMap));
      planogramList.push(buildPlanogram('6', planogramMap));
      planogramList.push(buildPlanogram('7', planogramMap));
      planogramList.push(buildPlanogram('8', planogramMap));
      planogramList.push(buildPlanogram('9', planogramMap));
      planogramList.push(buildPlanogram('10', planogramMap));
      planogramList.push(buildPlanogram('11', planogramMap));
      planogramList.push(buildPlanogram('12', planogramMap));
      planogramList.push(buildPlanogram('13', planogramMap));
      planogramList.push(buildPlanogram('14', planogramMap));
      planogramList.push(buildPlanogram('15', planogramMap));
      planogramList.push(buildPlanogram('16', planogramMap));
      planogramList.push(buildPlanogram('17', planogramMap));
      planogramList.push(buildPlanogram('18', planogramMap));
      planogramList.push(buildPlanogram('19', planogramMap));
      planogramList.push(buildPlanogram('20', planogramMap));
      planogramList.push(buildPlanogram('21', planogramMap));
      planogramList.push(buildPlanogram('22', planogramMap));
      planogramList.push(buildPlanogram('23', planogramMap));
      return planogramList;
    };
    this.state = {
      deviceType,
      buttonValue: [],
      dataInput: [],
      columns: buildColumns({}),
      planogram: buildPlanogramList({}),
      productMap: {},
      isEdit,
      deviceId,
      rows: [],
      planogram: [],
      dynamic_title: '',
      dynamic_description: '',
      confirm_alert: false,
      success_dlg: false,
      isLoading: true,
    };
    this.tableRef = React.createRef();
  }
  showToast() {
    var toastType = 'error';
    var message = 'Pilih product terlebih dahulu';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  showAdd(code) {
    var toastType;
    var message;
    if (code === 'Berhasil') {
      toastType = 'success';
      message = 'Berhasil Menambah Planogram';
    } else {
      toastType = 'error';
      message = 'Gagal Menambah Planogram';
    }

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  componentDidMount() {
    const { deviceId } = this.state;
    this.props.ReadPlanogram(deviceId);
    //     const formValues = safeDeepGet(
    //   this.props,
    //   ['readPlanogramResult', 'body', 'products'],
    //   []
    // );
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.createResult !== prevProps.createResult) {
      const code = safeDeepGet(this.props, ['createResult', 'code']);
      if (code == 0) {
        this.showAdd('Berhasil');
      } else if (code == 1) {
        this.showAdd('Gagal');
      }
    }
    if (this.props.readPlanogramResult !== prevProps.readPlanogramResult) {
      const code = safeDeepGet(
        this.props,
        ['readPlanogramResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      const history = safeDeepGet(
        this.props,
        ['readPlanogramResult', 'body', 'history', 'planogram'],
        []
      );
      const user = safeDeepGet(
        this.props,
        ['readPlanogramResult', 'body', 'history', 'user'],
        []
      );
      const name = safeDeepGet(
        this.props,
        ['readPlanogramResult', 'body', 'history', 'name'],
        []
      );
      const timeStamp = safeDeepGet(
        this.props,
        ['readPlanogramResult', 'body', 'history', 'time_stamp'],
        []
      );
      if (code === Result.SUCCESS) {
        const productMap = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'products'],
          {}
        );
        const planogramMap = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'planogram'],
          {}
        );
        this.setState({
          columns: buildColumns(productMap),
          planogram: buildPlanogramList(planogramMap),
          productMap,
          history: history,
          name: name,
          timeStamp: timeStamp,
          user: user,
          isLoading: false,
        });
      }
    }
  }
  handleDeleteClick = row => {
    const { deviceId } = this.state;
    this.setState({
      confirm_alert: false,
      success_dlg: true,
      dynamic_title: 'Deleted',
      dynamic_description: 'Your file has been deleted.',
    });
    this.props.DeletePlanogram(row);
    this.props.ReadPlanogram(deviceId);

    this.showDelete();
  };
  showDelete() {
    var toastType = 'success';
    var message = 'Berhasil Delete Planoggram';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  handleAddPlanogram = () => {
    const { deviceId } = this.state;
    if (this.state.selectedVM) {
      const value = safeDeepGet(this.props, ['readResult', 'body']);
      const sku = safeDeepGet(value, 'sku');
      const price = safeDeepGet(value, 'price');
      const display_name = safeDeepGet(value, 'display_name');
      const img_url = safeDeepGet(value, 'img_url');

      const payload = {
        sku: sku,
        price: price,
        display_name: display_name,
        img_url: img_url,
      };
      formValues.push(payload);
      this.setState({ selectedVM: null });
    } else {
      this.showToast();
    }
  };
  handleChangeProduct(value) {
    this.setState({ selectedVM: value });
    this.props.ReadProduct(value.value);
  }
  handleChangeOrder(value) {
    this.setState({ formValues: value });
  }
  handleSubmit(event) {
    const { deviceId, productMap, dataInput, deviceType } = this.state;
    const data = this.tableRef.current.props.data;
    const planogramMap = {};
    for (const i in data) {
      const planogram = data[i];
      const selection = planogram['selection'];
      const sku = planogram['sku'];
      planogramMap[selection] = {
        sku,
        name: safeDeepGet(productMap, sku, ''),
        price: Number(planogram['price']),
      };
    }
    this.props.AddPlanogram({
      id: deviceId,
      planogram: planogramMap,
      device_type: deviceType,
    });
  }

  handleChange(i, e) {
    if (e.target.name == 'order') {
      formValues[i][e.target.name] = Number(e.target.value);
    } else {
      formValues[i][e.target.name] = e.target.value;
    }
    this.setState({ formValues });
  }
  handleValidDate = date => {
    if (date != null || date != undefined) {
      const date1 = new Date(date).toLocaleDateString('en-GB');
      const time1 = new Date(date).toLocaleTimeString('en-GB');
      return date1 + ' ' + time1;
    } else {
      return '-';
    }
  };
  render() {
    const { columns, planogram, history, user, timeStamp, name,isLoading } = this.state;
    const filename = name + '_' + '_' + this.handleValidDate(timeStamp);
    const drole = localStorage.getItem('accessNav');
    const jsonRole = JSON.parse(drole);
    const guest = safeDeepGet(jsonRole, ['Business Point', 'guest'], 'off');
    return (
      <React.Fragment>
        <AvForm onValidSubmit={this.handleSubmit}>
          <div className="page-content">
            <MetaTags>
              <title>{buildTitle('Planogram')}</title>
            </MetaTags>
            <Container fluid>
              <Breadcrumbs title="Planogram" breadcrumbItem="Edit" />
              {this.state.success_dlg ? (
                <SweetAlert
                  success
                  title={this.state.dynamic_title}
                  onConfirm={() => this.setState({ success_dlg: false })}
                >
                  {this.state.dynamic_description}
                </SweetAlert>
              ) : null}
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">
                        Planogram Information
                      </CardTitle>
                      <p className="card-title-desc">Product Planogram</p>
                      {isLoading ? (
                        <div className="text-center">
                          <CircularProgress className="text-center"></CircularProgress>
                        </div>
                      ) : (
                        <div className="table-responsive">
                          <BootstrapTable
                            keyField="key"
                            bootstrap4={true}
                            data={planogram}
                            columns={columns}
                            cellEdit={cellEditFactory({
                              mode: 'click',
                              blurToSave: true,
                            })}
                            bordered={false}
                            ref={this.tableRef}
                          />
                        </div>
                      )}
                    </CardBody>
                  </Card>
                  <Col>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4 h5">
                          Latest Changes Planogram by
                        </CardTitle>

                        <Row className="align-items-center">
                          <Col lg="9" className="align-self-center">
                            <div className="table-responsive">
                              <Table className="table align-middle table-nowrap table-hover mb-0">
                                <thead>
                                  <tr>
                                    <th scope="col">Email</th>
                                    <th scope="col">Time</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>{user}</td>
                                    <td>
                                      {timeStamp == null
                                        ? '-'
                                        : this.handleValidDate(timeStamp)}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </Col>

                          <Col
                            lg="3"
                            className="d-none d-lg-block"
                            align="center"
                          >
                            <div className="clearfix mt-4 mt-lg-0">
                              <ExcelFile
                                filename={filename}
                                element={
                                  <button
                                    type="button"
                                    className="btn btn-primary w-sm"
                                  >
                                    <i className="mdi mdi-download d-block font-size-16"></i>
                                    Download
                                  </button>
                                }
                              >
                                <ExcelSheet
                                  data={history}
                                  name="Transaction List"
                                >
                                  <ExcelColumn label="Selection" value="key" />
                                  <ExcelColumn label="Product" value="name" />
                                  <ExcelColumn
                                    label="Product SKU"
                                    value="product"
                                  />
                                  <ExcelColumn
                                    label="Product Price"
                                    value="price"
                                  />
                                  <ExcelColumn label="Stock" value="stock" />
                                  <ExcelColumn label="Active" value="active" />
                                </ExcelSheet>
                              </ExcelFile>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  {isLoading ? (
                    <div className="d-flex flex-wrap gap-2 float-end mb-4">
                      <Link
                        className="btn btn-secondary"
                        role="button"
                        to="/business-point/list"
                      >
                        Batal
                      </Link>{' '}
                      {guest == 'off' ? (
                        <Button
                          color="primary"
                          onClick={this.componentDidMount}
                        >
                          Refresh
                        </Button>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <div className="d-flex flex-wrap gap-2 float-end mb-4">
                      <Link
                        className="btn btn-secondary"
                        role="button"
                        to="/business-point/list"
                      >
                        Batal
                      </Link>{' '}
                      {guest == 'off' ? (
                        <Button type="submit" color="primary">
                          Simpan
                        </Button>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        </AvForm>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  readPlanogramResult: safeDeepGet(
    state,
    ['businessPoint', 'readPlanogramMilano'],
    {}
  ),
  loading: safeDeepGet(state, ['businessPoint', 'loading'], true),
  readResult: safeDeepGet(state, ['product', 'read'], {}),
  createResult: safeDeepGet(state, ['businessPoint', 'updatePlanogramMilano']),
  test: state,
});

const mapDispatchToProps = dispatch => ({
  ReadProduct: deviceId => dispatch(read(deviceId)),
  DeletePlanogram: payload => dispatch(deletePlanogram(payload)),

  ReadPlanogram: id => dispatch(readPlanogramMilano(id)),
  AddPlanogram: payload => dispatch(updatePlanogramMilano(payload)),
  // UpdatePlanogram: payload => dispatch(updatePlanogram(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanogramNestle);
