import {
  LOADING,
  RESULT,
  GET_VM_STATUS,
  GET_NETT_SETTLEMENT
  ,GET_SNAPSHOT,
  GET_ROLE,
  GET_NOTIFICATION
} from "./actionTypes";

const INIT_STATE = {
  loading: true,
};

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
      case LOADING:
        return {
          ...state,
          loading: true,
        };
      case RESULT:
        const newState = {
          ...state,
          loading: false,
        };
        switch (action.payload.actionType) {
          case GET_SNAPSHOT:
            newState['get_snapshot'] = action.payload.result;
            break;
          case GET_VM_STATUS:
            newState["vm_status"] = action.payload.result;
            break;
          case GET_NETT_SETTLEMENT:
            newState["nett_settlement"] = action.payload.result;
            break;
          case GET_NOTIFICATION:
            newState["get_notification"] = action.payload.result;
            break;
          case GET_ROLE:
            newState["get_role"] = action.payload.result;
            break;
          }
        return newState;
  
      default:
        return state;
  }
}


export default Dashboard;