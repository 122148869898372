/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable semi */
/* Import */
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import { superRoutes } from 'Admin/routes';
import AppAdminRoute from 'Admin/routes/route';
import { APPLICATION_ID } from 'Apps';
import NonAuthLayout from 'Base/components/NonAuthLayout';
import { publicRoutes, authRoutes } from 'Base/routes';
import BaseRoute from 'Base/routes/route';

import { indessoRoutes } from 'Base/routes/index_indesso';
import { svFrankeRoutes } from 'SvNestleFranke/routes';
import AppSvFrankeRoute from 'SvNestleFranke/routes/route';
import { svNestleRoutes } from 'SvNestleMilano/routes';
import AppSvNestleRoute from 'SvNestleMilano/routes/route';

export default props => (
  <Router>
    {APPLICATION_ID == 'Admin' ? (
      <Switch>
        {publicRoutes.map((route, idx) => (
          <BaseRoute
            path={route.path}
            layout={NonAuthLayout}
            component={route.component}
            key={idx}
            isAuthProtected={false}
          />
        ))}

        {superRoutes.map((route, idx) => (
          <AppAdminRoute
            path={route.path}
            layout={props.layout}
            component={route.component}
            key={idx}
            isAuthProtected={true}
            exact
          />
        ))}
      </Switch>
    ) : APPLICATION_ID == '1000000159' ? (
      <Switch>
        {publicRoutes.map((route, idx) => (
          <BaseRoute
            path={route.path}
            layout={NonAuthLayout}
            component={route.component}
            key={idx}
            isAuthProtected={false}
          />
        ))}

        {svNestleRoutes.map((route, idx) => (
          <AppSvNestleRoute
            path={route.path}
            layout={props.layout}
            component={route.component}
            key={idx}
            isAuthProtected={true}
            exact
          />
        ))}
      </Switch>
    ) : APPLICATION_ID == '1000000211' ? (
      <Switch>
        {publicRoutes.map((route, idx) => (
          <BaseRoute
            path={route.path}
            layout={NonAuthLayout}
            component={route.component}
            key={idx}
            isAuthProtected={false}
          />
        ))}

        {svFrankeRoutes.map((route, idx) => (
          <AppSvFrankeRoute
            path={route.path}
            layout={props.layout}
            component={route.component}
            key={idx}
            isAuthProtected={true}
            exact
          />
        ))}
      </Switch>
    ) : APPLICATION_ID == '1000000226' ? (
      <Switch>
        {publicRoutes.map((route, idx) => (
          <BaseRoute
            path={route.path}
            layout={NonAuthLayout}
            component={route.component}
            key={idx}
            isAuthProtected={false}
          />
        ))}

        {indessoRoutes.map((route, idx) => (
          <BaseRoute
            path={route.path}
            layout={props.layout}
            component={route.component}
            key={idx}
            isAuthProtected={true}
            exact
          />
        ))}
      </Switch>
    ) : (
      // ) : APPLICATION_ID == '1000000232' ? (
      //   <Switch>
      //     {publicRoutes.map((route, idx) => (
      //       <BaseRoute
      //         path={route.path}
      //         layout={NonAuthLayout}
      //         component={route.component}
      //         key={idx}
      //         isAuthProtected={false}
      //       />
      //     ))}

      //     {indessoRoutes.map((route, idx) => (
      //       <BaseRoute
      //         path={route.path}
      //         layout={props.layout}
      //         component={route.component}
      //         key={idx}
      //         isAuthProtected={true}
      //         exact
      //       />
      //     ))}
      //   </Switch>

      <Switch>
        {publicRoutes.map((route, idx) => (
          <BaseRoute
            path={route.path}
            layout={NonAuthLayout}
            component={route.component}
            key={idx}
            isAuthProtected={false}
          />
        ))}

        {authRoutes.map((route, idx) => (
          <BaseRoute
            path={route.path}
            layout={props.layout}
            component={route.component}
            key={idx}
            isAuthProtected={true}
            exact
          />
        ))}
      </Switch>
    )}
  </Router>
);
