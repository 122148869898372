export const LOADING = "LOADING";
export const RESULT = "RESULT";

export const LIST = "ADMIN_PROJECT_LIST";
export const LIST_VM = "ADMIN_PROJECT_LIST_VM";
export const INIT_CREATE = "ADMIN_PROJECT_INIT_CREATE";
export const CREATE = "ADMIN_PROJECT_CREATE";
export const READ = "ADMIN_PROJECT_INFO";
export const UPDATE = "ADMIN_PROJECT_UPDATE";
export const STOCK_UPDATE = "STOCK_ADMIN_PROJECT_UPDATE";
export const DELETE = "ADMIN_PROJECT_DELETE";
export const IMPORT_PRODUCT = "ADMIN_PROJECT_IMPORT_PRODUCT";
export const SUMMARY = "ADMIN_PROJECT_SUMMARY";
export const GET_NOTIFICATION = "ADMIN_PROJECT_GET_NOTIFICATION";
export const PUSH_NOTIFICATION = "ADMIN_PROJECT_PUSH_NOTIFICATION";
