/* eslint-disable no-self-assign */
/* eslint-disable no-redeclare */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable semi */
import axios from 'axios';
const momenttz = require('moment-timezone');
const moment = require('moment');
import React, { Component } from 'react';
import Result from 'iotera-base/result';
import ReactExport from 'react-data-export';
import PropTypes from 'prop-types';
import Pages403 from 'Base/pages/pages-403';
import { NAVBAR, PERMISION } from 'Apps';
const navbar = NAVBAR;
const permision = PERMISION;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import {
  Label,
  Container,
  Row,
  Col,
  FormGroup,
  InputGroup,
  Button,
  Card,
  CardTitle,
  CardBody,
} from 'reactstrap';
import MetaTags from 'react-meta-tags';
import Select from 'react-select';
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';

import { connect } from 'react-redux';

// Iotera
import { safeDeepGet, safeDeepSet, safeDel } from 'iotera-base/utility/json';

import { get_nett_settlement } from '../../../store/dashboard/actions';

import {
  list_business_points,
  summary,
  get_sales_analytics,
  get_snapshot_monthly,
  get_report_group,
} from '../../../store/transaction/actions';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
//Import Components
import TransactionSummary from '../Transaction-report/transaction-summary'; //
import ProductSummary from '../Transaction-report/product-summary'; //
import Earning from '../Transaction-report/earning';
import SalesAnalytics from '../Transaction-report/sales-analytics';
import BPSalesAnalytics from '../Transaction-report/bp-sales-analytics';
import XlsxPopulate from 'xlsx-populate';
import {
  APPLICATION_ID,
  buildTitle,
  getButtonTheme,
  PROJECT_HANDLE,
} from 'Apps';

const bankOptions = [
  { label: 'All', value: 'all' },
  { label: 'CASH', value: 'CASH' },
  { label: 'ECR', value: 'ECR' },
  { label: 'RFID', value: 'RFID' },
  { label: 'QRIS-MIDTRANS', value: 'QRIS-MIDTRANS' },
  { label: 'QRIS-MIDTRANS-PARTNER', value: 'QRIS-MIDTRANS-PARTNER' },
  { label: 'QRIS-BANKNOBU', value: 'QRIS-BANKNOBU' },
];
const quarterfilterlist = [
  { label: 'Now', value: 'all' },
  { label: 'Q1', value: 'Q1' },
  { label: 'Q2', value: 'Q2' },
  { label: 'Q3', value: 'Q3' },
  { label: 'Q4', value: 'Q4' },
];
let role = '';
const obj = JSON.parse(localStorage.getItem('role'));
if (obj != null) {
  role = obj.role;
}
class TransactionReport extends Component {
  constructor(props) {
    super(props);
    this.refDateRangePicker = React.createRef();
    var current_month = new Date();
    const params = new URLSearchParams(window.location.href.split('?')[1]);
    let name = params.get('device_id');
    let bank = params.get('bank_status');
    let start = params.get('dateStart');
    let end = params.get('dateEnd');
    let q = params.get('q');
    let dateStart = new Date(Number(start));
    let dateEnd = new Date(Number(end));
    let businessPoints = { value: 'all' };
    let payment = { value: 'all' };
    let quarter = { value: 'all' };
    var current_month = new Date();
    var startDate = new Date(
      current_month.getFullYear(),
      current_month.getMonth(),
      1
    );
    if (name != undefined || name != null) {
      businessPoints = { value: name };
    }
    if (q != undefined || q != null) {
      quarter = { value: q };
    }
    if (bank != undefined || bank != null) {
      payment = { value: bank };
    }
    let arrDate = [startDate, current_month];
    if (start != undefined || start != null) {
      arrDate = [dateStart, dateEnd];
    }
    this.state = {
      bpfilterlist: [],
      bank: payment,
      businessPoints: businessPoints,
      quarter: quarter,
      downloadList: [],
      dateRange: arrDate,
      loading: false,
      reports: [
        {
          icon: 'bx bx-copy-alt',
          title: 'Landlord Profit Share',
          value: 'Rp 0',
          badgeValue: '+ 0.0%',
          color: 'primary',
          chevron: 'mdi mdi-chevron-up ms-1 text-success',
          desc: 'From previous period',
        },
        {
          icon: 'bx bx-copy-alt',
          title: 'Average Daily Transactions',
          value: '',
          badgeValue: '',
          color: 'primary',
          chevron: 'mdi mdi-chevron-up ms-1 text-success',
          desc: 'From previous period',
        },
        {
          icon: 'bx bx-purchase-tag-alt',
          title: 'Average Daily Refund',
          value: '',
          badgeValue: '',
          color: 'primary',
          chevron: 'mdi mdi-chevron-up ms-1 text-success',
          desc: 'From previous period',
        },
      ],
      Cups: [
        {
          icon: 'bx bx-archive-in',
          title: 'Average Daily Settlement',
          value: '',
          badgeValue: '',
          color: 'primary',
          chevron: 'mdi mdi-chevron-up ms-1 text-success',
          desc: 'From previous period',
        },
        {
          icon: 'bx bx-purchase-tag-alt',
          title: 'Total Settlement',
          value: '',
          badgeValue: '',
          color: 'primary',
          chevron: 'mdi mdi-chevron-up ms-1 text-success',
          desc: 'From previous period',
        },
      ],
      selectedMonthYear:
        current_month.getFullYear().toString() +
        '-' +
        (current_month.getMonth() + 1).toString(),
    };
    this.handleBusinessPoints = this.handleBusinessPoints.bind(this);
    this.handleQuarter = this.handleQuarter.bind(this);
    this.handleMonthYear = this.handleMonthYear.bind(this);
    this.handleBanks = this.handleBanks.bind(this);
    this.handleDateRange = this.handleDateRange.bind(this);
    this.handleClearDateRange = this.handleClearDateRange.bind(this);
    this.handleExport = this.handleExport.bind(this);
    this.handleExportFormated = this.handleExportFormated.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
  }

  handleFilter = () => {
    const { businessPoints, dateRange, bank } = this.state;
    const payload = {
      businessPoints: businessPoints.value,
      month: dateRange[0].getMonth(),
      year: dateRange[0].getFullYear(),
      dateRange: dateRange,
      bank: bank.value,
    };
    this.setState({ currentPage: 1 });
    var searchParams = new URLSearchParams(window.location.search);

    if (businessPoints.value == 'all') {
      searchParams.delete('device_id');
      this.props.history.push({
        pathname: '/transaction/report',
        search: searchParams.toString(),
      });
    } else {
      searchParams.set('device_id', businessPoints.value);
      this.props.history.push({
        pathname: '/transaction/report',
        search: searchParams.toString(),
      });
    }
    if (bank.value == 'all') {
      searchParams.delete('device_id');
      this.props.history.push({
        pathname: '/transaction/report',
        search: searchParams.toString(),
      });
    } else {
      searchParams.set('bank_status', bank.value);
      this.props.history.push({
        pathname: '/transaction/report',
        search: searchParams.toString(),
      });
    }

    this.props.LoadSnapshotMonthly(payload);
    this.setState({ loading: true });
  };

  handleBusinessPoints = businessPoints => {
    this.setState({ businessPoints });

    var searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('device_id');
    this.props.history.push({
      pathname: '/transaction/report',
      search: searchParams.toString(),
    });
  };
  handleQuarter = quarter => {
    this.setState({ quarter });

    const value = quarter.value;
    const quarterMap = {
      Q1: [0, 1, 2],
      Q2: [3, 4, 5],
      Q3: [6, 7, 8],
      Q4: [9, 10, 11],
    };
    const payload = {
      quarter: safeDeepGet(quarterMap, value),
      q: value,
    };
    // this.props.GetReportGroup(payload);

    var searchParams = new URLSearchParams(window.location.search);
    if (quarter.value == 'all') {
      searchParams.delete('q');
      this.props.history.push({
        pathname: '/transaction/report',
        search: searchParams.toString(),
      });
    } else {
      searchParams.set('q', quarter.value);
      this.props.history.push({
        pathname: '/transaction/report',
        search: searchParams.toString(),
      });
    }
  };

  handleBanks = bank => {
    this.setState({ bank });
    var searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('bank_status');
    this.props.history.push({
      pathname: '/transaction/report',
      search: searchParams.toString(),
    });
  };

  handleMonthYear = selectedMonthYear => {
    let val = selectedMonthYear.target.value;
    this.setState({ selectedMonthYear: val });

    const payload = {
      businessPoints: this.state.businessPoints.value,
      month: parseInt(val.split('-')[1]) - 1,
      year: parseInt(val.split('-')[0]),
      dateRange: [null, null],
      bank: this.state.bank.value,
    };
    this.props.LoadSnapshotMonthly(payload);

    // this.props.GetNettSettlement(payload);
    // this.props.Summary(payload);
    // this.props.GetSalesAnalytics(payload);
  };
  handleDateRange = dateRange => {
    if (dateRange.length < 2) {
      console.log('Ignoring.. only start date');
    } else {
      const timezone = 'Asia/Jakarta';
      moment.tz.setDefault(timezone);
      const now = momenttz.tz(dateRange[0], timezone);
      const Date_1 = now.clone().subtract(0, 'days').startOf('days');
      const startTime = Date_1.valueOf();
      var startfulldate = new Date(startTime);

      const end = momenttz.tz(dateRange[1], timezone);
      const Date_2 = end.clone().subtract(0, 'days').endOf('days');
      const endTime = Date_2.valueOf();
      var endfulldate = new Date(endTime);
      const Date_3 = end.clone().subtract(0, 'days').endOf('days');
      const endTimePayload = Date_3.valueOf();
      var endPayload = new Date(endTimePayload);

      this.setState({ dateRange: [startfulldate, endPayload] });

      const oneDay = 24 * 60 * 60 * 1000;

      const difference = Math.round(
        Math.abs((startfulldate - endfulldate) / oneDay)
      );
      const diffDays = difference;
      this.setState({ diffDays });

      var searchParams = new URLSearchParams(window.location.search);

      searchParams.delete('dateStart');
      searchParams.delete('dateEnd');
      this.props.history.push({
        pathname: '/transaction/report',
        search: searchParams.toString(),
      });
    }
  };

  handleClearDateRange = () => {
    console.log('dateRange is cleared');

    if (!this.refDateRangePicker.current?.flatpickr) {
      return;
    } else {
      this.refDateRangePicker.current.flatpickr.clear();
      this.setState(() => ({ dateRange: [null, null] }));
      const payload = {
        businessPoints: this.state.businessPoints.value,
        dateRange: [null, null],
        bank: this.state.bank.value,
        month: this.state.dateRange[0].getMonth(),
        year: this.state.dateRange[0].getFullYear(),
      };
      // this.props.GetNettSettlement(payload);
      // this.props.Summary(payload);
      // this.props.GetSalesAnalytics(payload);
    }
  };
  componentDidMount() {
    if (APPLICATION_ID == '1000000250' || APPLICATION_ID == '1000000021') {
      this.state.Cups.push({
        icon: 'bx bx-purchase-tag-alt',
        title: 'Hpp',
        value: '',
        badgeValue: '',
        color: 'primary',
        chevron: 'mdi mdi-chevron-up ms-1 text-success',
        desc: 'From previous period',
      });
    }
    var current_month = new Date();

    this.props.LoadBpFilterList();
    var current_month = new Date();
    const payload = {
      businessPoints: this.state.businessPoints.value,
      dateRange: this.state.dateRange,
      bank: this.state.bank.value,
      month: this.state.dateRange[0].getMonth(),
      year: this.state.dateRange[0].getFullYear(),
    };
    // this.setState({ dateRange: this.state.dateRange });
    // this.props.GetNettSettlement(payload);
    this.props.LoadSnapshotMonthly(payload);
    // this.props.Summary(payload);
    // this.props.GetSalesAnalytics(payload);
    this.setState({loading:true})

    const payload1 = {
      quarter: undefined,
    };
    // this.props.GetReportGroup(payload1);
  }

  componentDidUpdate(prevProps) {
    const sum_update = safeDeepGet(
      this.props,
      ['snapshotMonthlyresult', 'body'],
      []
    );
    let average = 0;
    if (this.state.diffDays != null) {
      average = this.state.diffDays;
    } else {
      const date = new Date();
      average = date.getDate();
    }
    if (
      this.props.snapshotMonthlyresult !== prevProps.snapshotMonthlyresult &&
      sum_update.length != 0
    ) {
      var stateCopy = Object.assign({}, this.state);
      stateCopy.reports = stateCopy.reports.slice();
      // Landlord Profit Share
      stateCopy.reports[0] = Object.assign({}, stateCopy.reports[0]);
      stateCopy.reports[0].value =
        'Rp ' + sum_update.landlord_current.toFixed(0);
      stateCopy.reports[0].badgeValue =
        sum_update.landlord_change.toFixed(2) + ' %';
      if (sum_update.landlord_change > 0) {
        stateCopy.reports[0].color = 'success';
        stateCopy.reports[0].chevron = 'mdi mdi-chevron-up ms-1 text-success';
      } else {
        stateCopy.reports[0].color = 'danger';
        stateCopy.reports[0].chevron = 'mdi mdi-chevron-down ms-1 text-danger';
      }

      // Average Daily Transactions
      stateCopy.reports[1] = Object.assign({}, stateCopy.reports[1]);
      const curent = sum_update.trx_current / average;
      const numberPrev = curent.toFixed(0).toString();
      // const arr_string = numberPrev.split('.')
      var number_string_curent = numberPrev.replace(/[^,\d]/g, '').toString();
      var split_curent = number_string_curent.split(',');
      var sisa_curent = split_curent[0].length % 3;
      let rupiah_curent = split_curent[0].substr(0, sisa_curent);
      var ribuan_curent = split_curent[0].substr(sisa_curent).match(/\d{3}/gi);

      // tambahkan titik jika yang di input sudah menjadi angka ribuan_curent
      if (ribuan_curent) {
        var separator_curent = sisa_curent ? '.' : '';
        rupiah_curent += separator_curent + ribuan_curent.join('.');
      }
      rupiah_curent =
        split_curent[1] != undefined
          ? rupiah_curent + ',' + split_prev[1]
          : rupiah_curent;
      stateCopy.reports[1].value = 'Rp ' + rupiah_curent;
      // change to format other than rupiah
      // stateCopy.reports[1].value =
      //   'Rp ' + sum_update.trx_current / (30.0).toFixed(2);
      stateCopy.reports[1].badgeValue = sum_update.trx_change.toFixed(2) + ' %';
      if (sum_update.trx_change > 0) {
        stateCopy.reports[1].color = 'success';
        stateCopy.reports[1].chevron = 'mdi mdi-chevron-up ms-1 text-success';
      } else {
        stateCopy.reports[1].color = 'danger';
        stateCopy.reports[1].chevron = 'mdi mdi-chevron-down ms-1 text-danger';
      }

      // Average Daily Settlement
      stateCopy.Cups[0] = Object.assign({}, stateCopy.Cups[0]);
      stateCopy.Cups[0].value =
        (sum_update.settlement_current / average).toFixed(0) + ' x';
      stateCopy.Cups[0].badgeValue =
        sum_update.settlement_change.toFixed(2) + ' %';
      if (sum_update.settlement_change > 0) {
        stateCopy.Cups[0].color = 'success';
        stateCopy.Cups[0].chevron = 'mdi mdi-chevron-up ms-1 text-success';
      } else {
        stateCopy.Cups[0].color = 'danger';
        stateCopy.Cups[0].chevron = 'mdi mdi-chevron-down ms-1 text-danger';
      }
      //Total cups
      stateCopy.Cups[1] = Object.assign({}, stateCopy.Cups[1]);
      stateCopy.Cups[1].value = sum_update.settlement_current.toFixed(0) + ' x';
      stateCopy.Cups[1].badgeValue =
        sum_update.settlement_change.toFixed(2) + ' %';
      if (sum_update.settlement_change > 0) {
        stateCopy.Cups[1].color = 'success';
        stateCopy.Cups[1].chevron = 'mdi mdi-chevron-up ms-1 text-success';
      } else {
        stateCopy.Cups[1].color = 'danger';
        stateCopy.Cups[1].chevron = 'mdi mdi-chevron-down ms-1 text-danger';
      }

      // Average Daily Refund
      stateCopy.reports[2] = Object.assign({}, stateCopy.reports[2]);

      const refund = sum_update.refund_current;
      const numberRefund = refund.toFixed(0).toString();
      var number_string_Refund = numberRefund.replace(/[^,\d]/g, '').toString();
      var split_Refund = number_string_Refund.split(',');
      var sisa_Refund = split_Refund[0].length % 3;
      let rupiah_Refund = split_Refund[0].substr(0, sisa_Refund);
      var ribuan_Refund = split_Refund[0].substr(sisa_Refund).match(/\d{3}/gi);

      // // tambahkan titik jika yang di input sudah menjadi angka ribuan_Refund
      if (ribuan_Refund) {
        var separator_curent = sisa_Refund ? '.' : '';
        rupiah_Refund += separator_curent + ribuan_Refund.join('.');
      }
      rupiah_Refund =
        split_Refund[1] != undefined
          ? rupiah_Refund + ',' + split_Refund[1]
          : rupiah_Refund;

      stateCopy.reports[2].value = 'Rp ' + rupiah_Refund;
      stateCopy.reports[2].badgeValue =
        sum_update.refund_change.toFixed(2) + ' %';
      if (sum_update.refund_change >= 0) {
        stateCopy.reports[2].color = 'success';
        stateCopy.reports[2].chevron = 'mdi mdi-chevron-up ms-1 text-success';
      } else {
        stateCopy.reports[2].color = 'danger';
        stateCopy.reports[2].chevron = 'mdi mdi-chevron-down ms-1 text-danger';
      }

      if (APPLICATION_ID == '1000000250') {
        // Average HPP Transactions
        stateCopy.Cups[2] = Object.assign({}, stateCopy.Cups[2]);
        const hppCurent = sum_update.hpp_current;
        const hppNumberPrev = hppCurent.toFixed(0).toString();
        // const arr_string = numberPrev.split('.')
        var hpp_number_string_curent = hppNumberPrev
          .replace(/[^,\d]/g, '')
          .toString();
        var hpp_split_curent = hpp_number_string_curent.split(',');
        var hpp_sisa_curent = hpp_split_curent[0].length % 3;
        let hpp_rupiah_curent = hpp_split_curent[0].substr(0, hpp_sisa_curent);
        var hpp_ribuan_curent = hpp_split_curent[0]
          .substr(hpp_sisa_curent)
          .match(/\d{3}/gi);

        // tambahkan titik jika yang di input sudah menjadi angka hpp_ribuan_curent
        if (hpp_ribuan_curent) {
          var separator_curent = hpp_sisa_curent ? '.' : '';
          hpp_rupiah_curent += separator_curent + hpp_ribuan_curent.join('.');
        }
        hpp_rupiah_curent =
          hpp_split_curent[3] != undefined
            ? hpp_rupiah_curent + ',' + split_prev[3]
            : hpp_rupiah_curent;
        stateCopy.Cups[2].value = 'Rp ' + hpp_rupiah_curent;
        // change to format other than rupiah
        // stateCopy.Cups[2].value =
        //   'Rp ' + sum_update.trx_current / (30.0).toFixed(2);
        stateCopy.Cups[2].badgeValue = sum_update.hpp_change.toFixed(2) + ' %';
        if (sum_update.hpp_change > 0) {
          stateCopy.Cups[2].color = 'success';
          stateCopy.Cups[2].chevron = 'mdi mdi-chevron-up ms-1 text-success';
        } else {
          stateCopy.Cups[2].color = 'danger';
          stateCopy.Cups[2].chevron = 'mdi mdi-chevron-down ms-1 text-danger';
        }
      }

      this.setState(stateCopy);
      this.setState({loading: false})
    }

    if (this.props.getReportGroupResult !== prevProps.getReportGroupResult) {
      // Success create
      const code = safeDeepGet(
        this.props,
        ['getReportGroupResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      const bpfilterlist = safeDeepGet(
        this.props,
        ['bpfilterresult', 'body'],
        []
      );
      const bpFilter = [];
      const deviceIdFilter = [];
      for (const i in bpfilterlist) {
        const data = bpfilterlist[i];
        const label = safeDeepGet(data, 'label', '');
        const value = safeDeepGet(data, 'value', '');
        bpFilter.push(label);
        deviceIdFilter.push(value);
      }

      if (code === Result.SUCCESS || code === Result.HTTP.OK) {
        const reportBody = safeDeepGet(
          this.props,
          ['getReportGroupResult', 'body', 'dataGroup'],
          []
        );
        const subHeader = safeDeepGet(
          this.props,
          ['getReportGroupResult', 'body', 'header'],
          []
        );
        const standarAlphabet = [...'CDEFGHIJKLMNOPQRSTUVWXYZ'];
        const downloadList = [];
        const rangeHeader = [];
        const header = [];
        const start = 'AA';
        const end = 'AZ';
        const result = [];
        for (let i = start.charCodeAt(0); i <= end.charCodeAt(0); i++) {
          for (let j = start.charCodeAt(1); j <= 'Z'.charCodeAt(0); j++) {
            result.push(String.fromCharCode(i) + String.fromCharCode(j));
          }
        }
        const alphabetArray = standarAlphabet.concat(result);
        let long = 0;
        let long2 = 0;
        const body = {};
        const productBody = {};
        const amount_product = {};

        for (const month in reportBody) {
          const arr_header = [];
          header.push(month);
          const bodyMonth = safeDeepGet(reportBody, month, {});
          for (const date in bodyMonth) {
            arr_header.push(date);
            safeDeepSet(body, date, '');
            const bodyDate = safeDeepGet(bodyMonth, date, {});
            for (const j in bodyDate) {
              const bodyDevice = safeDeepGet(bodyDate, j, {});
              for (const device in bodyDevice) {
                const data = safeDeepGet(bodyDevice, device, {});
                // console.log(data)
                for (const i in data) {
                  const amount = safeDeepGet(data, [i, 'settle'], 0);
                  const last_amount = safeDeepGet(
                    productBody,
                    [device, i, month, date],
                    0
                  );
                  if (last_amount == 0) {
                    safeDeepSet(productBody, [device, i, month, date], amount);
                  } else {
                    const new_amount = last_amount + amount;
                    safeDel(productBody, [device, i, month, date]);
                    safeDeepSet(
                      productBody,
                      [device, i, month, date],
                      new_amount
                    );
                  }
                  const nameProduct = safeDeepGet(
                    data,
                    [i, 'nameProduct'],
                    '-'
                  );
                  safeDeepSet(
                    productBody,
                    [device, i, 'nameProduct'],
                    nameProduct
                  );
                  // safeDeepSet(productBody, [device, i, month, date], amount);
                }
              }
            }
          }
          //Get End of header
          long2 = long + arr_header.length;
          const range = alphabetArray[long] + '1:' + alphabetArray[long2] + '1';
          // + for start header
          long2 += 1;
          long += arr_header.length + 1;
          rangeHeader.push(range);
          long2 = 0;
        }
        let long_product = 0;
        let i = 1;
        let filterTotal = [];
        for (const data in productBody) {
          const productUn = safeDeepGet(productBody, data, {});
          const bpBody = {};
          const bpName = { no: '' };
          safeDeepSet(bpName, 'nameProduct', data);
          for (const k in header) {
            const month = header[k];
            const a = header.indexOf(month) + 1;
            for (const j in subHeader) {
              const date = subHeader[j];
              if (date.includes(month)) {
                safeDeepSet(bpName, date, '');
              }
            }
            safeDeepSet(bpName, 'total' + a, '');
          }
          downloadList.push(bpName);
          const product = Object.keys(productUn)
            .sort()
            .reduce((obj, key) => {
              obj[key] = productUn[key];
              return obj;
            }, {});
          for (const i in product) {
            const body = {};
            const dataMonth = safeDeepGet(product, i, {});
            const nameProduct = safeDeepGet(product, [i, 'nameProduct'], '');
            safeDeepSet(body, 'no', '');
            safeDeepSet(body, 'nameProduct', nameProduct);
            for (const k in header) {
              const month = header[k];
              const a = header.indexOf(month) + 1;
              let amount = 0;
              const dataProduct = safeDeepGet(dataMonth, month, {});
              for (const j in subHeader) {
                const date = subHeader[j];
                if (date.includes(month)) {
                  const settle = safeDeepGet(dataMonth, [month, date], 0);
                  amount += settle;
                  // safeDeepSet(bpName, date, '');
                  safeDeepSet(body, date, settle);
                }
              }
              safeDeepSet(body, 'total' + a, amount);
            }
            downloadList.push(body);
            long_product += 1;
          }
          i += 1;
          safeDeepSet(bpBody, 'nameProduct', 'amount' + i);
          safeDeepSet(amount_product, 'amount' + i, long_product);
          filterTotal.push('amount' + i++);
          long_product = 0;
          downloadList.push(bpBody);
        }
        this.setState({
          filterTotal,
          header,
          rangeHeader,
          subHeader,
          downloadList,
          bpFilter,
          deviceIdFilter,
          amount_product,
        });
      }
    }
  }

  handleValidDate = date => {
    const date1 = date.toDate().toLocaleDateString('en-GB');
    const time1 = date.toDate().toLocaleTimeString('en-GB');
    return date1 + ' ' + time1;
  };
  getSheetData(data, header) {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : '';
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }
  handleExportFormated = list => {
    const {
      filterTotal,
      header,
      rangeHeader,
      subHeader,
      downloadList,
      bpFilter,
      deviceIdFilter,
      amount_product,
    } = this.state;
    var data = downloadList;
    const alphabetArray = [...'CDEFGHIJKLMNOPQRSTUVWXYZ'];

    const { selectedMonthYear, businessPoints, dateRange } = this.state;
    let bp_name = businessPoints.value ? businessPoints.label : businessPoints;
    const lenght = downloadList.lenght;
    XlsxPopulate.fromBlankAsync().then(async workbook => {
      const sheet1 = workbook.sheet(0);
      sheet1.column('A').width(20);
      sheet1.column('B').width(40);
      let sheetData;
      sheetData = this.getSheetData(downloadList, header);
      sheetData.shift();
      const totalColumns = sheetData[0].length;
      sheet1
        .range('A1:A2')
        .value('NO')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
          fill: '7B7B7B',
          fontColor: 'FFFFFF',
        })
        .merged(true);
      sheet1
        .range('B1:B2')
        .value('Product & Business Point')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
          fill: '7B7B7B',
          fontColor: 'FFFFFF',
        })
        .merged(true);
      for (const i in header) {
        sheet1
          .range(rangeHeader[i])
          .value(header[i])
          .style({
            horizontalAlignment: 'center',
            verticalAlignment: 'center',
            border: true,
            fill: '7B7B7B',
            fontColor: 'FFFFFF',
          })
          .merged(true);
      }
      for (const i in subHeader) {
        sheet1
          .cell(alphabetArray[i] + '2')
          .value(subHeader[i].includes('total') ? 'Total' : subHeader[i])
          .style({
            horizontalAlignment: 'center',
            verticalAlignment: 'center',
            border: true,
            fill: '7B7B7B',
            fontColor: 'FFFFFF',
          });
      }
      const cell_arr = [];
      const number_arr = [];
      const number_arr2 = [];
      const formula_arr = [];
      const bp_cell = [];
      sheetData.forEach((row, rowIndex) => {
        const row_array = [];
        row.forEach((cellValue, colIndex) => {
          const cell = sheet1.cell(rowIndex + 3, colIndex + 1); // Start from A3
          cell.value(
            typeof cellValue == 'string'
              ? cellValue.includes('amount')
                ? 'Total'
                : cellValue
              : cellValue
          );
          if (bpFilter.includes(cellValue)) {
            for (const i in subHeader) {
              const j = Number(i) + Number(2);
              const alpha = alphabetArray[i];
              for (const k in amount_product) {
                const cell = `${alpha}${rowIndex + 3}`;
                const number_cell = `A${rowIndex + 3}`;
                if (!number_arr.includes(number_cell)) {
                  number_arr.push(number_cell);
                }
                if (!bp_cell.includes(`B${rowIndex + 3}`)) {
                  bp_cell.push(`B${rowIndex + 3}`);
                }
                if (!bp_cell.includes(cell)) {
                  bp_cell.push(cell);
                }
              }
            }
            cell.style({
              horizontalAlignment: 'center',
              verticalAlignment: 'center',
              border: true,
              fill: 'FFFF00',
              fontColor: 'FF2B1A',
            });
          }
          if (cellValue != undefined) {
            if (filterTotal.includes(cellValue)) {
              row_array.push(rowIndex);
              for (const i in subHeader) {
                const j = Number(i) + Number(2);
                const alpha = alphabetArray[i];
                for (const k in amount_product) {
                  const cell = `${alpha}${rowIndex + 3}`;
                  const number_cell = `A${rowIndex + 3}`;
                  const formula = `SUM(${alpha}${rowIndex + 2}:${alpha}${
                    rowIndex + 3 - amount_product[cellValue]
                  })`;
                  if (!cell_arr.includes(cell)) {
                    cell_arr.push(cell);
                  }
                  if (!formula_arr.includes(formula)) {
                    formula_arr.push(formula);
                  }
                  if (!number_arr2.includes(number_cell)) {
                    number_arr2.push(number_cell);
                  }
                }
              }
              cell.style({
                horizontalAlignment: 'center',
                verticalAlignment: 'center',
                border: true,
                fill: 'FFFF00',
                fontColor: 'FF2B1A',
              });
            } else {
              if (!cell_arr.includes(cell)) {
                cell.style({
                  horizontalAlignment: 'center',
                  verticalAlignment: 'center',
                  border: true,
                  fill: 'FFFFFF',
                  fontColor: '000000',
                });
              }
            }
          }
        });
      });
      for (const i in cell_arr) {
        const cell = cell_arr[i];
        const formula = formula_arr[i];
        sheet1.cell(cell).formula(formula).style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
          fill: 'FFFF00',
          fontColor: 'FF2B1A',
        });
      }
      for (const i in bp_cell) {
        const cell = bp_cell[i];
        sheet1.cell(cell).style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
          fill: '2eff22',
          fontColor: '203764',
        });
      }
      for (const i in number_arr) {
        const cell = number_arr[i];
        const number = Number(i) + 1;
        sheet1.cell(cell).value(number).style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
          fill: '2eff22',
          fontColor: '203764',
        });
      }
      for (const i in number_arr2) {
        const cell = number_arr2[i];
        sheet1.cell(cell).style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
          fill: 'FFFF00',
          fontColor: 'FF2B1A',
        });
      }
      var quarter = this.state.quarter.label;
      var report_filename = quarter + '.xlsx';
      var tenant = PROJECT_HANDLE;
      if (PROJECT_HANDLE === 'Fuji') {
        tenant = 'VendkiosK';
      } else if (APPLICATION_ID == '1000000159') {
        tenant = 'Milano';
      }
      if (quarter != undefined) {
        report_filename =
          tenant +
          '_' +
          quarter +
          '_Report_' +
          bp_name +
          '_' +
          selectedMonthYear +
          '.xlsx';
      } else {
        report_filename =
          tenant +
          '_Now' +
          '_Report_' +
          bp_name +
          '_' +
          selectedMonthYear +
          '.xlsx';
      }

      return setTimeout(
        workbook.outputAsync().then(res => {
          saveAs(res, report_filename);
        }),
        2000
      );
    });
  };

  handleExport = list => {
    var data = list;
    let header = [
      'Device ID',
      'Business Point',
      'Tanggal 01',
      'Tanggal 02',
      'Tanggal 03',
      'Tanggal 04',
      'Tanggal 05',
      'Tanggal 06',
      'Tanggal 07',
      'Tanggal 08',
      'Tanggal 09',
      'Tanggal 10',
      'Tanggal 11',
      'Tanggal 12',
      'Tanggal 13',
      'Tanggal 14',
      'Tanggal 15',
      'Tanggal 16',
      'Tanggal 17',
      'Tanggal 18',
      'Tanggal 19',
      'Tanggal 20',
      'Tanggal 21',
      'Tanggal 22',
      'Tanggal 23',
      'Tanggal 24',
      'Tanggal 25',
      'Tanggal 26',
      'Tanggal 27',
      'Tanggal 28',
      'Tanggal 29',
      'Tanggal 30',
      'Tanggal 31',
    ];

    const { selectedMonthYear, businessPoints, dateRange } = this.state;
    let bp_name = businessPoints.value ? businessPoints.label : businessPoints;
    XlsxPopulate.fromBlankAsync().then(async workbook => {
      const sheet1 = workbook.sheet(0);
      sheet1.column('A').width(40);
      sheet1.column('B').width(40);
      let sheetData;
      sheetData = this.getSheetData(data, header);
      sheetData.shift();
      const totalColumns = sheetData[0].length;
      sheet1
        .range('A1:A2')
        .value('Business Point')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1
        .range('B1:B2')
        .value('Serial Number')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1
        .range('C1:D1')
        .value('Tanggal 01')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('C2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('D2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1
        .range('E1:F1')
        .value('Tanggal 02')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('E2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('F2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1
        .range('G1:H1')
        .value('Tanggal 03')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('G2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('H2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1
        .range('I1:J1')
        .value('Tanggal 04')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('I2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('J2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1
        .range('K1:L1')
        .value('Tanggal 05')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('K2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('L2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1
        .range('M1:N1')
        .value('Tanggal 06')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('M2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('N2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1
        .range('O1:P1')
        .value('Tanggal 07')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('O2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('P2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1
        .range('Q1:R1')
        .value('Tanggal 08')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('Q2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('R2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1
        .range('S1:T1')
        .value('Tanggal 09')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('S2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('T2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1
        .range('U1:V1')
        .value('Tanggal 10')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('U2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('V2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1
        .range('W1:X1')
        .value('Tanggal 11')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('W2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('X2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1
        .range('Y1:Z1')
        .value('Tanggal 12')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('Y2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('Z2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1
        .range('AA1:AB1')
        .value('Tanggal 13')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('AA2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('AB2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1
        .range('AC1:AD1')
        .value('Tanggal 14')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('AC2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('AD2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1
        .range('AE1:AF1')
        .value('Tanggal 15')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('AE2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('AF2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1
        .range('AG1:AH1')
        .value('Tanggal 16')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('AG2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('AH2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });

      sheet1
        .range('AI1:AJ1')
        .value('Tanggal 17')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('AI2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('AJ2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });

      sheet1
        .range('AK1:AL1')
        .value('Tanggal 18')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('AK2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('AL2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });

      sheet1
        .range('AM1:AN1')
        .value('Tanggal 19')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('AM2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('AN2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });

      sheet1
        .range('AO1:AP1')
        .value('Tanggal 20')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('AO2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('AP2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });

      sheet1
        .range('AQ1:AR1')
        .value('Tanggal 21')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('AQ2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('AR2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });

      sheet1
        .range('AS1:AT1')
        .value('Tanggal 22')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('AS2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('AT2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });

      sheet1
        .range('AU1:AV1')
        .value('Tanggal 23')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('AU2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('AV2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });

      sheet1
        .range('AW1:AX1')
        .value('Tanggal 24')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('AW2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('AX2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });

      sheet1
        .range('AY1:AZ1')
        .value('Tanggal 25')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('AY2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('AZ2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });

      sheet1
        .range('BA1:BB1')
        .value('Tanggal 26')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('BA2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('BB2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });

      sheet1
        .range('BC1:BD1')
        .value('Tanggal 27')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('BC2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('BD2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });

      sheet1
        .range('BE1:BF1')
        .value('Tanggal 28')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('BE2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('BF2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });

      sheet1
        .range('BG1:BH1')
        .value('Tanggal 29')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('BG2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('BH2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });

      sheet1
        .range('BI1:BJ1')
        .value('Tanggal 30')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('BI2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('BJ2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1
        .range('BK1:BL1')
        .value('Tanggal 31')
        .style({
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          border: true,
        })
        .merged(true);
      sheet1.cell('BK2').value('Total Sales').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('BL2').value('Total Transaction').style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      sheet1.cell('A3').value(sheetData).style({
        horizontalAlignment: 'center',
        verticalAlignment: 'center',
        border: true,
      });
      var report_filename = '.xlsx';
      var tenant = PROJECT_HANDLE;
      if (PROJECT_HANDLE === 'Fuji') {
        tenant = 'VendkiosK';
      } else if (APPLICATION_ID == '1000000159') {
        tenant = 'Milano';
      }
      if (bp_name == undefined) {
        if (dateRange[0] == undefined) {
          report_filename =
            tenant + '_Trx_Report_All_' + selectedMonthYear + '.xlsx';
        } else {
          report_filename =
            tenant +
            '_Trx_Report_All_' +
            dateRange[0].toLocaleDateString('en-GB') +
            ' to ' +
            dateRange[1].toLocaleDateString('en-GB') +
            '.xlsx';
        }
      } else {
        if (dateRange[0] == undefined) {
          report_filename =
            tenant +
            '_Trx_Report_' +
            bp_name +
            '_' +
            selectedMonthYear +
            '.xlsx';
        } else {
          report_filename =
            tenant +
            '_Trx_Report_' +
            bp_name +
            '_' +
            dateRange[0].toLocaleDateString('en-GB') +
            ' to ' +
            dateRange[1].toLocaleDateString('en-GB') +
            '.xlsx';
        }
      }
      return workbook.outputAsync().then(res => {
        saveAs(res, report_filename);
      });
    });
  };
  format_report = list => {
    const dataSet = [];
    let trx_data = {};
    if (list.length > 0) {
      for (const el in list) {
        const dataArr = safeDeepGet(list[el], 'data', 0);
        trx_data = {
          business_point: safeDeepGet(list[el], 'name', '-'),
          serial_number: safeDeepGet(
            list[el],
            'serial_number',
            safeDeepGet(list[el], 'deviceId', '-')
          ),
        };
        // console.log(dataArr);
        for (const i in dataArr) {
          const date = safeDeepGet(dataArr[i], 'x');
          // console.log(date)
          const total = safeDeepGet(dataArr[i], 'y', 0);
          const settle = safeDeepGet(dataArr[i], 'settle', 0);
          safeDeepSet(trx_data, 'total' + i, total);
          safeDeepSet(trx_data, 'settle' + i, settle);
        }
        dataSet.push(trx_data);
      }
      const dataArr = Object.keys(safeDeepGet(list[0], 'data', 0));
      const keys = Object.keys(list[0]);
      const columns = [];
      for (const el in keys) {
        if (keys[el] !== 'data') {
          columns.push({
            title: keys[el],
            width: { wch: 40 },
            border: {
              bottom: { style: 'thin', color: { rgb: '000000' } },
              top: { style: 'thin', color: { rgb: '000000' } },
              left: { style: 'thin', color: { rgb: '000000' } },
              right: { style: 'thin', color: { rgb: '000000' } },
            },
          });
        }
      }
      for (const el in dataArr) {
        columns.push({
          title: 'Tanggal ' + (Number(dataArr[el]) + 1),
          alignment: {
            horizontal: 'center',
          },
          border: {
            bottom: { style: 'thin', color: { rgb: '000000' } },
            top: { style: 'thin', color: { rgb: '000000' } },
            left: { style: 'thin', color: { rgb: '000000' } },
            right: { style: 'thin', color: { rgb: '000000' } },
          },
          width: { wpx: 80 },
        });
      }
      const data = [];
      for (const el in list) {
        const listData = [];
        const dataArr = safeDeepGet(list[el], 'data', 0);

        listData.push({
          value: safeDeepGet(list[el], 'deviceId', '-'),
          style: {
            font: { sz: '12' },
            alignment: {
              wrapText: true,
            },
            border: {
              bottom: { style: 'thin', color: { rgb: '000000' } },
              top: { style: 'thin', color: { rgb: '000000' } },
              left: { style: 'thin', color: { rgb: '000000' } },
              right: { style: 'thin', color: { rgb: '000000' } },
            },
          },
        });
        listData.push({
          value: safeDeepGet(list[el], 'serial_number', '-'),
          style: {
            font: { sz: '12' },
            alignment: {
              wrapText: true,
            },
            border: {
              bottom: { style: 'thin', color: { rgb: '000000' } },
              top: { style: 'thin', color: { rgb: '000000' } },
              left: { style: 'thin', color: { rgb: '000000' } },
              right: { style: 'thin', color: { rgb: '000000' } },
            },
          },
        });
        for (const i in dataArr) {
          const total = safeDeepGet(dataArr[i], 'y', 0);
          const settle = safeDeepGet(dataArr[i], 'settle', 0);
          listData.push({
            value: total,
            style: {
              font: { sz: '12' },
              alignment: {
                wrapText: true,
                horizontal: 'center',
              },
              border: {
                bottom: { style: 'thin', color: { rgb: '000000' } },
                top: { style: 'thin', color: { rgb: '000000' } },
                left: { style: 'thin', color: { rgb: '000000' } },
                right: { style: 'thin', color: { rgb: '000000' } },
              },
              numFmt: '0',
            },
          });
          listData.push({
            value: settle,
            style: {
              font: { sz: '12' },
              alignment: {
                wrapText: true,
                horizontal: 'center',
              },
              border: {
                bottom: { style: 'thin', color: { rgb: '000000' } },
                top: { style: 'thin', color: { rgb: '000000' } },
                left: { style: 'thin', color: { rgb: '000000' } },
                right: { style: 'thin', color: { rgb: '000000' } },
              },
              numFmt: '0',
            },
          });

          // safeDeepSet(trx_data, i, total);
          // safeDeepSet(trx_data, "settle"+i, settle);
        }
        data.push(listData);
      }
      const objDataSet = { columns: columns, data: data };
      const multiDataSet = [objDataSet];
      return dataSet;
    }
  };

  render() {
    const bpfilterlist = safeDeepGet(
      this.props,
      ['bpfilterresult', 'body'],
      []
    );
    const nett_settlement = safeDeepGet(
      this.props,
      ['snapshotMonthlyresult', 'nett_settlement'],
      []
    );
    const sales_analytics = safeDeepGet(
      this.props,
      ['snapshotMonthlyresult', 'sales_analytics'],
      []
    );
    const bp_sales_analytics = safeDeepGet(
      this.props,
      ['snapshotMonthlyresult', 'bp_sales_analytics'],
      []
    );
    // const downloadData = safeDeepGet(nett_settlement, 'download_data', []);
    const downloadData = safeDeepGet(
      this.props,
      ['snapshotMonthlyresult', 'download_data'],
      []
    );
    const report_list = this.format_report(downloadData);
    const { selectedMonthYear } = this.state;
    let { dateRange, bank, businessPoints, quarter, downloadList } = this.state;

    let bp_name = businessPoints.value ? businessPoints.label : businessPoints;
    let report_filename = ' ';
    if (bp_name == undefined) {
      if (dateRange[0] == undefined) {
        report_filename = 'Trx_Report_All_' + selectedMonthYear;
      } else {
        report_filename =
          'Trx_Report_All_' +
          dateRange[0].toLocaleDateString('en-GB') +
          ' to ' +
          dateRange[1].toLocaleDateString('en-GB');
      }
    } else {
      if (dateRange[0] == undefined) {
        report_filename = 'Trx_Report_' + bp_name + '_' + selectedMonthYear;
      } else {
        report_filename =
          'Trx_Report_' +
          bp_name +
          '_' +
          dateRange[0].toLocaleDateString('en-GB') +
          ' to ' +
          dateRange[1].toLocaleDateString('en-GB');
      }
    }
    const params = new URLSearchParams(window.location.href.split('?')[1]);
    let name = params.get('device_id');
    let q = params.get('q');
    let payment = params.get('bank_status');
    let start = params.get('dateStart');
    let end = params.get('dateEnd');
    let dateStart = new Date(Number(start));
    let dateEnd = new Date(Number(end));
    if (start != undefined || start != null) {
      dateRange = [dateStart, dateEnd];
    } else {
      dateRange = dateRange;
    }
    if (payment != undefined || payment != null) {
      bank = payment;
    } else {
      bank = bank.value;
    }
    if (name != undefined || name != null) {
      businessPoints = name;
    } else {
      businessPoints = businessPoints.value;
    }
    if (q != undefined || q != null) {
      quarter = q;
    } else {
      quarter = quarter.value;
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{buildTitle('Transaction Report')}</title>
          </MetaTags>
          {!permision ||
          safeDeepGet(navbar, ['Transaction', 'view'], 'off') == 'off' ? (
            <Pages403 />
          ) : (
            <>
              <Container fluid>
                {/* Render Breadcrumb */}
                <Breadcrumbs title="Transaction" breadcrumbItem="Reports" />
                {this.state.loading ? (
                  <div className="clearfix mt-4 mt-lg-0">
                    <div
                      className="loading-spinner"
                      style={{
                        position: 'fixed',
                        top: '0',
                        left: '0',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        zIndex: '9999',
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="150"
                        height="150"
                        viewBox="0 0 100 100"
                        preserveAspectRatio="xMidYMid"
                        className="lds-ring"
                      >
                        <circle
                          cx="50"
                          cy="50"
                          r="40"
                          stroke="#007bff"
                          strokeWidth="10"
                          strokeDasharray="62.83185307179586 62.83185307179586"
                          fill="none"
                          strokeLinecap="round"
                          transform="rotate(215.582 50 50)"
                        >
                          <animateTransform
                            attributeName="transform"
                            type="rotate"
                            calcMode="linear"
                            values="0 50 50;360 50 50"
                            keyTimes="0;1"
                            dur="1s"
                            begin="0s"
                            repeatCount="indefinite"
                          ></animateTransform>
                        </circle>
                      </svg>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <Row className="clearfix mb-3">
                  <Col sm="2">
                    <FormGroup className="mb-2 ajax-select select2-container">
                      <Select
                        defaultValue={bpfilterlist[0]}
                        placeholder={'Business Point'}
                        // value={businessPoints}
                        value={bpfilterlist.filter(function (option) {
                          return option.value === businessPoints;
                        })}
                        onChange={this.handleBusinessPoints}
                        options={bpfilterlist}
                        classNamePrefix="select2-selection"
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="2">
                    <FormGroup className="mb-2 ajax-select select2-container">
                      <Select
                        placeholder={'Payment'}
                        // defaultValue={bankOptions[0]}
                        // value={bank}
                        value={bankOptions.filter(function (option) {
                          return option.value === bank;
                        })}
                        onChange={this.handleBanks}
                        options={bankOptions}
                        classNamePrefix="select2-selection"
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="3" align="center">
                    <Row>
                      <Col>
                        <Button color="primary" onClick={this.handleFilter}>
                          Apply
                        </Button>{' '}
                        <Button
                          type="button"
                          className={getButtonTheme()}
                          disabled={downloadData.length == 0 ? true : false}
                          onClick={() => this.handleExport(report_list)}
                        >
                          <i className="mdi mdi-download font-size-12 m-auto"></i>{' '}
                          {'Download Excel'}
                        </Button>
                      </Col>
                    </Row>
                  </Col>

                  <Col sm={2}>
                    <Label className="col-md-12 col-form-label text-sm-end">
                      Date Range
                    </Label>
                  </Col>
                  <Col sm="3" className="text-end">
                    <InputGroup>
                      <Flatpickr
                        className="form-control d-block"
                        placeholder="dd M,yyyy"
                        options={{
                          mode: 'range',
                          dateFormat: 'Y-m-d',
                        }}
                        value={dateRange}
                        onChange={this.handleDateRange}
                        // ref={this.refDateRangePicker}
                      />
                    </InputGroup>
                  </Col>
                </Row>

                <Row>
                  <Col xs="12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                      <h4 className="mb-0 font-size-18">TRANSACTION SUMMARY</h4>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xl="12">
                    <Row>
                      {/*mimi widgets */}
                      <TransactionSummary reports={this.state.reports} />
                    </Row>
                  </Col>
                </Row>

                {/* earning */}
                <Row className="clearfix">
                  <Earning data={nett_settlement} />
                </Row>
                <Row className="clearfix">
                  <BPSalesAnalytics series={bp_sales_analytics} />
                </Row>
              </Container>

              <Container fluid>
                {/* <Row className="clearfix mb-3">
                  <Card>
                    <CardTitle className="mt-3">
                      Download Data Per Quarter
                    </CardTitle>
                    <CardBody>
                      <Row className="clearfix ">
                        <Col sm="4"></Col>
                        <Col sm="2">
                          <FormGroup className="mb-2 ajax-select select2-container">
                            <Select
                              defaultValue={quarterfilterlist[0]}
                              placeholder={'Quarter'}
                              // value={businessPoints}
                              value={quarterfilterlist.filter(function (
                                option
                              ) {
                                return option.value === quarter;
                              })}
                              onChange={this.handleQuarter}
                              options={quarterfilterlist}
                              classNamePrefix="select2-selection"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="6" align="center">
                          <div className="clearfix mt-4 mt-lg-0 text-sm-start">
                            <Button
                              type="button"
                              color="primary"
                              className="btn btn-nestle w-sm"
                              disabled={downloadList.length == 0 ? true : false}
                              onClick={() =>
                                this.handleExportFormated(report_list)
                              }
                            >
                              <i className="mdi mdi-download font-size-12 m-auto"></i>{' '}
                              {quarter == 'all'
                                ? 'Download data'
                                : 'Download data ' + quarter}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Row> */}

                <Row>
                  <Col xs="12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                      <h4 className="mb-0 font-size-18">PRODUCT SUMMARY</h4>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xl="12">
                    <Row>
                      {/*mimi widgets */}
                      <ProductSummary reports={this.state.Cups} />
                    </Row>
                  </Col>
                </Row>
                {/* sales anytics */}
                <Row className="clearfix">
                  <SalesAnalytics series={sales_analytics} />
                </Row>
              </Container>
            </>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  bpfilterresult: safeDeepGet(
    state,
    ['transaction', 'list_business_points'],
    {}
  ),
  summaryresult: safeDeepGet(state, ['transaction', 'summary'], {}),
  snapshotMonthlyresult: safeDeepGet(
    state,
    ['transaction', 'get_snapshot_monthly'],
    {}
  ),
  getReportGroupResult: safeDeepGet(
    state,
    ['transaction', 'get_report_group'],
    {}
  ),
  netsettlementresult: safeDeepGet(state, ['dashboard', 'nett_settlement'], {}),
  salesanalyticsresult: safeDeepGet(
    state,
    ['transaction', 'get_sales_analytics'],
    {}
  ),
  loading: safeDeepGet(state, ['dashboard', 'loading'], true),
});

const mapDispatchToProps = dispatch => ({
  LoadBpFilterList: () => dispatch(list_business_points()),
  // Summary: (businessPoints, month, year, dateRange, bank) =>
  //   dispatch(summary(businessPoints, month, year, dateRange, bank)),
  // GetNettSettlement: (businessPoints, month, year, dateRange, bank) =>
  //   dispatch(get_nett_settlement(businessPoints, month, year, dateRange, bank)),
  // GetSalesAnalytics: (businessPoints, month, year, dateRange, bank) =>
  //   dispatch(get_sales_analytics(businessPoints, month, year, dateRange, bank)),
  LoadSnapshotMonthly: (businessPoints, month, year, dateRange, bank) =>
    dispatch(
      get_snapshot_monthly(businessPoints, month, year, dateRange, bank)
    ),
  GetReportGroup: payload => dispatch(get_report_group(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionReport);
