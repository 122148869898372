/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable no-dupe-keys */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
// Strap
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Container,
  Label,
  Input,
  Table,
  FormGroup,
  Modal, ModalBody
} from 'reactstrap';
// Table
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import {
  list_product,
  deletePlanogram,
} from '../../../SvNestleMilano/store/business-point/actions';
import { read } from '../../../SvNestleMilano/store/product/actions';
// Breadcrumbs
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Result from 'iotera-base/result';

// Iotera
import { safeDeepGet } from 'iotera-base/utility/json';
// Redux
import { NAME_PROJECT, buildTitle, APPLICATION_ID } from 'Apps';
import { connect } from 'react-redux';
import {
  list_history,
  readPlanogramAlegria,
  updatePlanogramAlegria,
} from '../../store/business-point/actions';
let buildColumns = [];
let buildPlanogramList = {};
//SweetAlert
import SweetAlert from 'react-bootstrap-sweetalert';

import ReactExport from 'react-data-export';
import { CircularProgress } from '@material-ui/core';
import FailedAnimation from 'Base/assets/FailedAnimation';
import SuccessAnimation from 'Base/assets/SuccessAnimation';
import LoadingAnimation from 'Base/assets/LoadingAnimation';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class PlanogramNestle extends Component {
  constructor(props) {
    super(props);
    let isEdit = false;
    const { data, type } = props;
    var deviceId;
    if (APPLICATION_ID == '1000000159') {
      deviceId = safeDeepGet(this.props.match.params, 'id');
    } else {
      deviceId = data;
    }

    const deviceType = type;
    this.state = {};
    this.handleChangeProduct = this.handleChangeProduct.bind(this);
    // this.handleFormChange = this.handleFormChange.bind(this);
    this.handleChangeOrder = this.handleChangeOrder.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showToast = this.showToast.bind(this);
    this.showAdd = this.showAdd.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.showDelete = this.showDelete.bind(this);
    this.togglePlanogram = this.togglePlanogram.bind(this);
    buildColumns = productMap => {
      const productList = [];
      for (const sku in productMap) {
        productList.push({
          label: productMap[sku],
          value: sku,
        });
      }
      return [
        {
          dataField: 'selection',
          text: 'Selection',
          editable: false,
        },
        {
          dataField: 'sku',
          text: 'Product SKU',
          // editor: {
          //   type: Type.SELECT,
          //   getOptions: (setOptions, { row, column }) => {
          //     return productList;
          //   },
          // },
          // formatter: (cell, row, rowIndex, productMap) => (
          //   <>
          //     <p>{safeDeepGet(productMap, row.sku, '')}</p>
          //   </>
          // ),

          // formatExtraData: productMap,
          // style: (cell, row, rowIndex, colIndex, productMap) => {
          //   if (row.stock <= 3) {
          //     return {
          //       backgroundColor: '#FDC8C8',
          //     };
          //   }
          // },
          // editCellStyle: (cell, row, rowIndex, colIndex) => {
          //   if (row.stock <= 3) {
          //     return {
          //       backgroundColor: '#FDC8C8',
          //     };
          //   }
          // },
        },
        {
          dataField: 'name',
          text: 'Product',
          // editor: {
          //   type: Type.SELECT,
          //   getOptions: (setOptions, { row, column }) => {
          //     return productList;
          //   },
          // },
          // formatter: (cell, row, rowIndex, productMap) => (
          //   <>
          //     <p>{safeDeepGet(productMap, row.sku, '')}</p>
          //   </>
          // ),

          // formatExtraData: productMap,
          // style: (cell, row, rowIndex, colIndex, productMap) => {
          //   if (row.stock <= 3) {
          //     return {
          //       backgroundColor: '#FDC8C8',
          //     };
          //   }
          // },
          // editCellStyle: (cell, row, rowIndex, colIndex) => {
          //   if (row.stock <= 3) {
          //     return {
          //       backgroundColor: '#FDC8C8',
          //     };
          //   }
          // },
        },
        {
          dataField: 'price',
          text: 'Price (Rp)',
          validator: (newValue, row, column) => {
            if (!/^\d+$/.test(newValue)) {
              return {
                valid: false,
              };
            }
            return true;
          },
        },
        {
          dataField: 'active',
          text: 'Active',
          editor: {
            type: Type.CHECKBOX,
          },
          formatter: (cell, row, rowIndex) => {
            return (
              <Input
                type="checkbox"
                defaultChecked={safeDeepGet(row, ['active'], 'off') === 'on'}
              />
            );
          },
          style: (cell, row, rowIndex, colIndex, productMap) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
          editCellStyle: (cell, row, rowIndex, colIndex) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
        },
        {
          dataField: 'bypass',
          text: 'Bypass',
          editor: {
            type: Type.CHECKBOX,
          },
          formatter: (cell, row, rowIndex) => {
            return (
              <Input
                type="checkbox"
                defaultChecked={safeDeepGet(row, ['bypass'], 'off') === 'on'}
              />
            );
          },
          style: (cell, row, rowIndex, colIndex, productMap) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
          editCellStyle: (cell, row, rowIndex, colIndex) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
        },
      ];
    };
    const buildPlanogram = (id, planogramMap) => {
      const selection = safeDeepGet(planogramMap[id], 'selection');
      const sku = safeDeepGet(planogramMap[id], 'sku');
      const name = safeDeepGet(planogramMap[id], 'name');
      const price = safeDeepGet(planogramMap[id], 'price');
      const active =
        safeDeepGet(planogramMap[id], 'active', false) === true ? 'on' : 'off';
      const bypass =
        safeDeepGet(planogramMap[id], 'bypass', false) === true ? 'on' : 'off';
      return {
        key: selection,
        selection,
        sku,
        price,
        name,
        bypass,
        active,
      };
    };
    buildPlanogramList = planogramMap => {
      const planogramList = [];
      for(const i in planogramMap){
        planogramList.push(buildPlanogram(i, planogramMap));
      }
      return planogramList;
    };
    this.state = {
      deviceType,
      buttonValue: [],
      dataInput: [],
      columns: buildColumns({}),
      planogram: buildPlanogramList({}),
      productMap: {},
      isEdit,
      deviceId,
      rows: [],
      planogram: [],
      dynamic_title: '',
      dynamic_description: '',
      confirm_alert: false,
      success_dlg: false,
      isLoading: true,
      modalPlanogram: false,
      planogram_history: [],
    };
    this.tableRef = React.createRef();
  }
  showToast() {
    var toastType = 'error';
    var message = 'Pilih product terlebih dahulu';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  showAdd(code) {
    var toastType;
    var message;
    if (code === 'Berhasil') {
      toastType = 'success';
      message = 'Berhasil Menambah Planogram';
    } else {
      toastType = 'error';
      message = 'Gagal Menambah Planogram';
    }

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  togglePlanogram() {
    this.setState(prevState => ({
      modalPlanogram: !prevState.modalPlanogram,
    }));
  }
  componentDidMount() {
    const { deviceId } = this.state;
    this.props.ReadPlanogram(deviceId);
    //     const formValues = safeDeepGet(
    //   this.props,
    //   ['readPlanogramResult', 'body', 'products'],
    //   []
    // );
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.createResult !== prevProps.createResult) {
      const code = safeDeepGet(this.props, ['createResult', 'code']);
      if (code == 0) {
        this.showAdd('Berhasil');
      } else if (code == 1) {
        this.showAdd('Gagal');
      }
    }
    if (this.props.readPlanogramResult !== prevProps.readPlanogramResult) {
      const code = safeDeepGet(
        this.props,
        ['readPlanogramResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      const history = safeDeepGet(
        this.props,
        ['readPlanogramResult', 'body', 'history', 'planogram'],
        []
      );
      const user = safeDeepGet(
        this.props,
        ['readPlanogramResult', 'body', 'history', 'user'],
        []
      );
      const name = safeDeepGet(
        this.props,
        ['readPlanogramResult', 'body', 'history', 'name'],
        []
      );
      const timeStamp = safeDeepGet(
        this.props,
        ['readPlanogramResult', 'body', 'history', 'time_stamp'],
        []
      );
      if (code === Result.SUCCESS) {
        const productMap = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'products'],
          {}
        );
        const planogramMap = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'planogram'],
          {}
        );
        const payment_timeout = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'timeout'],
          60
        );
        this.setState({
          columns: buildColumns(productMap),
          planogram: buildPlanogramList(planogramMap),
          productMap,
          history: history,
          name: name,
          timeStamp: timeStamp,
          user: user,
          isLoading: false,
          payment_timeout,
        });
      }
    }
    if (this.props.result !== prevProps.result) {
      const code = safeDeepGet(
        this.props,
        ['result', 'code'],
        Result.UNKNOWN_ERROR
      );

      const message = safeDeepGet(
        this.props,
        ['result', 'message', 'text'],
        ''
      );
      const serverless_text = safeDeepGet(
        this.props,
        ['result', 'message', 'message'],
        message
      );
      const serverless_code = safeDeepGet(
        this.props,
        ['result', 'message', 'result'],
        20
      );

      const ts = safeDeepGet(
        this.props,
        ['result', 'ts'],
        new Date().getTime()
      );
      const planogram_history = safeDeepGet(
        this.props,
        ['result', 'planogram_history'],
        []
      );
      const name = safeDeepGet(this.props, ['result', 'name'], this.state.name);
      const mssage_serveless =
        'Message: ' + serverless_text + ' error code ' + serverless_code;
      this.setState({
        loading: false,
        serverless_code,
        ts,
        planogram_history,
        name,
      });
      setTimeout(() => {
        if (code === Result.SUCCESS) {
          // this.showToast('Update Planogram Successful', code, mssage_serveless);
          // setTimeout(() => window.location.reload(), 2000);
          this.componentDidMount();
        } else if (code !== Result.UNKNOWN_ERROR) {
          // this.showToast('Update Planogram Failed', code, mssage_serveless);
        }
      }, 2000);
    }
  }
  handleDeleteClick = row => {
    const { deviceId } = this.state;
    this.setState({
      confirm_alert: false,
      success_dlg: true,
      dynamic_title: 'Deleted',
      dynamic_description: 'Your file has been deleted.',
    });
    this.props.DeletePlanogram(row);
    this.props.ReadPlanogram(deviceId);

    this.showDelete();
  };
  showDelete() {
    var toastType = 'success';
    var message = 'Berhasil Delete Planoggram';

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  handleAddPlanogram = () => {
    const { deviceId } = this.state;
    if (this.state.selectedVM) {
      const value = safeDeepGet(this.props, ['readResult', 'body']);
      const sku = safeDeepGet(value, 'sku');
      const price = safeDeepGet(value, 'price');
      const display_name = safeDeepGet(value, 'display_name');
      const img_url = safeDeepGet(value, 'img_url');

      const payload = {
        sku: sku,
        price: price,
        display_name: display_name,
        img_url: img_url,
      };
      formValues.push(payload);
      this.setState({ selectedVM: null });
    } else {
      this.showToast();
    }
  };
  handleChangeProduct(value) {
    this.setState({ selectedVM: value });
    this.props.ReadProduct(value.value);
  }
  handleChangeOrder(value) {
    this.setState({ formValues: value });
  }
  get_history() {
    const { deviceId } = this.state;
    const payload = {
      device_id: deviceId,
    };
    this.props.GetHistory(payload);
  }
  handleSubmit(event, values) {
    this.get_history();
    this.togglePlanogram();
    this.setState({
      loading: true,
    });
    const { deviceId, productMap, deviceType } = this.state;
    const data = this.tableRef.current.props.data;
    const planogramMap = {};
    const timeout = safeDeepGet(values, 'payment_timeout', 60);
    for (const i in data) {
      const planogram = data[i];
      const selection = planogram['selection'];
      const sku = planogram['sku'];
      const name = planogram['name'];
      const active = planogram['active'] == 'on' ? true : false;
      const bypass = planogram['bypass'] == 'on' ? true : false;
      planogramMap[selection] = {
        sku,
        name,
        active,
        bypass,
        price: Number(planogram['price']),
      };
    }
    this.props.AddPlanogram({
      id: deviceId,
      planogram: planogramMap,
      timeout,
      device_type: deviceType,
      waitResult: true,
    });
  }

  handleChange(i, e) {
    if (e.target.name == 'order') {
      formValues[i][e.target.name] = Number(e.target.value);
    } else {
      formValues[i][e.target.name] = e.target.value;
    }
    this.setState({ formValues });
  }
  handleValidDate = date => {
    if (date != null || date != undefined) {
      const date1 = new Date(date).toLocaleDateString('en-GB');
      const time1 = new Date(date).toLocaleTimeString('en-GB');
      return date1 + ' ' + time1;
    } else {
      return '-';
    }
  };
  render() {
    const {
      columns,
      planogram,
      history,
      user,
      timeStamp,
      name,
      isLoading,
      serverless_code,
      ts,
      planogram_history,
      payment_timeout,
    } = this.state;
    const filename = name + '_' + '_' + this.handleValidDate(timeStamp);
    const drole = localStorage.getItem('accessNav');
    const jsonRole = JSON.parse(drole);
    const guest = safeDeepGet(jsonRole, ['Business Point', 'guest'], 'off');
    return (
      <React.Fragment>
        <AvForm onValidSubmit={this.handleSubmit}>
          <div className="page-content">
            <MetaTags>
              <title>{buildTitle('Planogram')}</title>
            </MetaTags>
            <Container fluid>
              <Breadcrumbs title="Planogram" breadcrumbItem="Edit" />
              {this.state.success_dlg ? (
                <SweetAlert
                  success
                  title={this.state.dynamic_title}
                  onConfirm={() => this.setState({ success_dlg: false })}
                >
                  {this.state.dynamic_description}
                </SweetAlert>
              ) : null}
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">
                        Planogram Information
                      </CardTitle>
                      <p className="card-title-desc">Product Planogram</p>
                      {isLoading ? (
                        <div className="text-center">
                          <CircularProgress className="text-center"></CircularProgress>
                        </div>
                      ) : (
                        <div className="table-responsive">
                          <BootstrapTable
                            keyField="key"
                            bootstrap4={true}
                            data={planogram}
                            columns={columns}
                            cellEdit={cellEditFactory({
                              mode: 'click',
                              blurToSave: true,
                            })}
                            bordered={false}
                            ref={this.tableRef}
                          />
                            <FormGroup className="mb-3 mx-3">
                              <Label>Payment Timeout</Label>{' '}
                              <Tippy content={'Waktu tunggu Pembayaran QRIS'}>
                                <Link className="text-info" to="#">
                                  <i
                                    className="mdi mdi-alert-circle-outline font-size-15"
                                    id="menutooltip"
                                    onClick={() => {}}
                                  ></i>
                                </Link>
                              </Tippy>
                              <AvField
                                value={payment_timeout ?? 60}
                                name="payment_timeout"
                                placeholder="Minimom Cash Denom"
                                type="number"
                                errorMessage="Masukkan Nominal dengan benar"
                                className="form-control"
                                validate={{ required: { value: true } }}
                              />
                            </FormGroup>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                  <Col>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4 h5">
                          Latest Changes Planogram by
                        </CardTitle>

                        <Row className="align-items-center">
                          <Col lg="9" className="align-self-center">
                            <div className="table-responsive">
                              <Table className="table align-middle table-nowrap table-hover mb-0">
                                <thead>
                                  <tr>
                                    <th scope="col">Email</th>
                                    <th scope="col">Time</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>{user}</td>
                                    <td>
                                      {timeStamp == null
                                        ? '-'
                                        : this.handleValidDate(timeStamp)}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </Col>

                          <Col
                            lg="3"
                            className="d-none d-lg-block"
                            align="center"
                          >
                            <div className="clearfix mt-4 mt-lg-0">
                              <ExcelFile
                                filename={filename}
                                element={
                                  <button
                                    type="button"
                                    className="btn btn-primary w-sm"
                                  >
                                    <i className="mdi mdi-download d-block font-size-16"></i>
                                    Download
                                  </button>
                                }
                              >
                                <ExcelSheet
                                  data={history}
                                  name="Transaction List"
                                >
                                  <ExcelColumn label="Selection" value="key" />
                                  <ExcelColumn label="Product" value="name" />
                                  <ExcelColumn
                                    label="Product SKU"
                                    value="product"
                                  />
                                  <ExcelColumn
                                    label="Product Price"
                                    value="price"
                                  />
                                  <ExcelColumn label="Stock" value="stock" />
                                  <ExcelColumn label="Active" value="active" />
                                </ExcelSheet>
                              </ExcelFile>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  {isLoading ? (
                    <div className="d-flex flex-wrap gap-2 float-end mb-4">
                      <Link
                        className="btn btn-secondary"
                        role="button"
                        to="/business-point/list"
                      >
                        Batal
                      </Link>{' '}
                      {guest == 'off' ? (
                        <Button
                          color="primary"
                          onClick={this.componentDidMount}
                        >
                          Refresh
                        </Button>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <div className="d-flex flex-wrap gap-2 float-end mb-4">
                      <Link
                        className="btn btn-secondary"
                        role="button"
                        to="/business-point/list"
                      >
                        Batal
                      </Link>{' '}
                      {guest == 'off' ? (
                        <Button type="submit" color="primary">
                          Simpan
                        </Button>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </Col>
              </Row>

              <Modal
              key="modal"
              size="lg"
              isOpen={this.state.modalPlanogram}
              className={this.props.className}
              toggle={this.togglePlanogram}
              fullscreen
            >
              {/* <ModalHeader toggle={this.togglePlanogram} tag="h4"></ModalHeader> */}
              <ModalBody>
                <div className=" text-left">
                  {serverless_code == Result.NETWORK_FAILURE ? (
                    <FailedAnimation />
                  ) : serverless_code == Result.TIMEOUT ? (
                    <FailedAnimation />
                  ) : serverless_code == -11 ? (
                    <FailedAnimation />
                  ) : serverless_code == Result.FAILED ? (
                    <FailedAnimation />
                  ) : serverless_code == Result.INPUT_FAILURE ? (
                    <FailedAnimation />
                  ) : serverless_code == 0 ? (
                    <SuccessAnimation />
                  ) : (
                    <LoadingAnimation />
                  )}
                  {serverless_code == 20 ? (
                    <h3 className="text-center">
                      Sedang memproses data, mohon tunggu sebentar...
                    </h3>
                  ) : (
                    <div>
                      <Row>
                        <Col sm="4"></Col>
                        <Col sm="4">
                          <Card className="border-solid border-black">
                            <div className="table-responsive mb-2">
                              <Table className="border-solid border-2 table align-middle table-wrap">
                                <thead>
                                  <tr>
                                    <th scope="col" className="text-start">
                                      <div>
                                        <h5 className="font-size-14">
                                          Planogram ID:
                                        </h5>
                                      </div>
                                    </th>
                                    <th scope="row" className="text-end">
                                      <div>
                                        <h5 className="font-size-14">{ts}</h5>
                                      </div>
                                    </th>
                                  </tr>
                                  <tr>
                                    <th scope="col" className="text-start">
                                      <div>
                                        <h5 className="font-size-14">
                                          Update By:
                                        </h5>
                                      </div>
                                    </th>
                                    <th scope="row" className="text-end">
                                      <div>
                                        <h5 className="font-size-14">
                                          {localStorage.getItem('email')}
                                        </h5>
                                      </div>
                                    </th>
                                  </tr>
                                  <tr>
                                    <th scope="col" className="text-start">
                                      <div>
                                        <h5 className="font-size-14">
                                          Update Time:
                                        </h5>
                                      </div>
                                    </th>
                                    <th scope="row" className="text-end">
                                      <div>
                                        <h5 className="font-size-14">
                                          {this.handleValidDate(ts)}
                                        </h5>
                                      </div>
                                    </th>
                                  </tr>
                                  <tr>
                                    <th scope="col" className="text-start">
                                      <div>
                                        <h5 className="font-size-14">
                                          Status:
                                        </h5>
                                      </div>
                                    </th>
                                    <th scope="row" className="text-end">
                                      <div>
                                        {serverless_code ===
                                        Result.NETWORK_FAILURE ? (
                                          <h5 className="font-size-14">
                                            Proses gagal, mesin sedang offline.
                                          </h5>
                                        ) : serverless_code ===
                                          Result.TIMEOUT ? (
                                          <h5 className="font-size-14">
                                            Proses gagal, mesin tidak memberikan
                                            respon.
                                          </h5>
                                        ) : serverless_code === -11 ? (
                                          <h5 className="font-size-14">
                                            Proses gagal, mesin sedang sibuk
                                            melakukan transaksi.
                                          </h5>
                                        ) : serverless_code ===
                                          Result.FAILED ? (
                                          <h5 className="font-size-14">
                                            Proses gagal, mesin tidak merespons
                                            atau sedang offline.
                                          </h5>
                                        ) : serverless_code ===
                                          Result.INPUT_FAILURE ? (
                                          <h5 className="font-size-14">
                                            Proses gagal, format planogram
                                            salah. Silakan hubungi Tim Iotera.
                                          </h5>
                                        ) : serverless_code ===
                                          Result.SUCCESS ? (
                                          <h5 className="font-size-14">
                                            Proses Selesai, mesin telah di
                                            update dengan planogram baru.
                                          </h5>
                                        ) : (
                                          <h5 className="font-size-14">
                                            Sedang memproses data, mohon tunggu
                                            sebentar...
                                          </h5>
                                        )}
                                      </div>
                                    </th>
                                  </tr>
                                  {serverless_code === Result.SUCCESS ? (
                                    <tr>
                                      <th scope="col" className="text-start">
                                        <div>
                                          <h5 className="font-size-14">
                                            Planogram data
                                          </h5>
                                        </div>
                                      </th>
                                      <th scope="row" className="text-end">
                                        <div className="clearfix mt-4 mt-lg-0">
                                          <ExcelFile
                                            filename={
                                              name +
                                              '_' +
                                              this.handleValidDate(ts)
                                            }
                                            element={
                                              <button
                                                type="button"
                                                className="btn btn-primary w-sm"
                                              >
                                                <i className="mdi mdi-download d-block font-size-16"></i>
                                                Download
                                              </button>
                                            }
                                          >
                                            {APPLICATION_ID == '1000000211' ||
                                            APPLICATION_ID == '1000000226' ? (
                                              <ExcelSheet
                                                data={planogram_history}
                                                name="Planogram History"
                                              >
                                                <ExcelColumn
                                                  label="selection"
                                                  value="selection"
                                                  numFmt="0"
                                                />
                                                <ExcelColumn
                                                  label="sku"
                                                  value="sku"
                                                />
                                                <ExcelColumn
                                                  label="name"
                                                  value="name"
                                                />
                                                <ExcelColumn
                                                  label="price"
                                                  value="price"
                                                  numFmt="0"
                                                />
                                              </ExcelSheet>
                                            ) : APPLICATION_ID ==
                                              '1000000258' ? (
                                              <ExcelSheet
                                                data={planogram_history}
                                                name="Planogram History"
                                              >
                                                <ExcelColumn
                                                  label="pulse"
                                                  value="pulse"
                                                  numFmt="0"
                                                />
                                                <ExcelColumn
                                                  label="duration"
                                                  value="duration"
                                                />
                                                <ExcelColumn
                                                  label="price"
                                                  value="price"
                                                  numFmt="0"
                                                />
                                              </ExcelSheet>
                                            ) : (
                                              <ExcelSheet
                                                data={
                                                  planogram_history
                                                }
                                                name="Planogram History"
                                              >
                                                <ExcelColumn
                                                  label="Selection"
                                                  value="key"
                                                />
                                                <ExcelColumn
                                                  label="Product"
                                                  value="name"
                                                />
                                                <ExcelColumn
                                                  label="Product SKU"
                                                  value="product"
                                                />
                                                <ExcelColumn
                                                  label="Product Price"
                                                  value="price"
                                                  numFmt="0"
                                                />
                                                <ExcelColumn
                                                  label="Stock"
                                                  value="stock"
                                                />
                                                <ExcelColumn
                                                  label="Active"
                                                  value="active"
                                                />
                                                <ExcelColumn
                                                  label="Column"
                                                  value="column"
                                                />
                                              </ExcelSheet>
                                            )}
                                          </ExcelFile>
                                        </div>
                                      </th>
                                    </tr>
                                  ) : (
                                    <></>
                                  )}
                                </thead>
                              </Table>
                            </div>
                          </Card>
                        </Col>
                        <Col sm="4"></Col>
                      </Row>
                    </div>
                  )}
                </div>
                <div className="text-center mt-4">
                  {serverless_code == Result.SUCCESS ? (
                    <Link
                      to={'/business-point/history/planogram'}
                      style={{
                        color: 'white',
                      }}
                      className="mb-2 btn btn-primary"
                    >
                      Next
                    </Link>
                  ) : serverless_code == 20 ? (
                    <></>
                  ) : (
                    <Button color="primary" onClick={this.togglePlanogram}>
                      Close
                    </Button>
                  )}
                </div>
              </ModalBody>
            </Modal>
            </Container>
          </div>
        </AvForm>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  readPlanogramResult: safeDeepGet(
    state,
    ['businessPoint', 'readPlanogramAlegria'],
    {}
  ),
  loading: safeDeepGet(state, ['businessPoint', 'loading'], true),
  readResult: safeDeepGet(state, ['product', 'read'], {}),
  result: safeDeepGet(state, ['businessPoint', 'updatePlanogramAlegria']),
  test: state,
});

const mapDispatchToProps = dispatch => ({
  ReadProduct: deviceId => dispatch(read(deviceId)),
  DeletePlanogram: payload => dispatch(deletePlanogram(payload)),
  GetHistory: payload => dispatch(list_history(payload)),
  ReadPlanogram: id => dispatch(readPlanogramAlegria(id)),
  AddPlanogram: payload => dispatch(updatePlanogramAlegria(payload)),
  // UpdatePlanogram: payload => dispatch(updatePlanogram(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanogramNestle);
