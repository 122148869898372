/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable semi */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import SweetAlert from 'react-bootstrap-sweetalert';

import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  Input,
  Label,
  ModalBody,
} from 'reactstrap';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';
import { AvForm, AvField } from 'availity-reactstrap-validation';

//Tippy
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
//Toast
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

//Import Breadcrumb
import Breadcrumbs from 'Base/components/Common/Breadcrumb';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet } from 'iotera-base/utility/json';

// Redux
import { connect } from 'react-redux';
import { list, update, delete_ } from '../../store/Role/actions';
import { APPLICATION_ID, buildTitle, getButtonTheme } from 'Apps';
const drole = localStorage.getItem('accessNav');
const jsonRole = JSON.parse(drole);
const guest = safeDeepGet(jsonRole, ['Management', 'guest'], 'off');
const edit = safeDeepGet(jsonRole, ['Management', 'edit'], 'off');

import Pages403 from '../pages-403';
import { NAVBAR, PERMISION } from 'Apps'
const navbar = NAVBAR;
const permision = PERMISION;
class RoleList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      modal: false,
      confirm_alert: false,
      modalNav: false,
      Role: [],
      columnBp: [
        {
          dataField: 'name',
          text: 'Name',
          editable: false,
          formatter: (cell, row, rowIndex) => {
            return <>{row.name}</>;
          },
        },
        {
          dataField: 'active',
          text: 'Active',
          editable: false,
          formatter: (cell, row, rowIndex) => {
            return (
              <>
                <Input
                  disabled
                  type="checkbox"
                  defaultChecked={safeDeepGet(row, ['active'], 'off') === 'on'}
                />
              </>
            );
          },
        },
      ],
      column: [
        {
          dataField: 'id',
          text: 'id',
          hidden: 'true',
          editable: false,
          formatter: (cell, row, rowIndex) => {
            return <>{row.id}</>;
          },
        },
        {
          dataField: 'nav',
          text: 'Name',
          editable: false,
          formatter: (cell, row, rowIndex) => {
            return <>{row.name}</>;
          },
        },
        {
          dataField: 'view',
          text: 'View',
          editable: false,
          formatter: (cell, row, rowIndex) => {
            return (
              <>
                <Input
                  type="checkbox"
                  disabled
                  defaultChecked={safeDeepGet(row, ['view'], 'off') === 'on'}
                />
              </>
            );
          },
        },
        {
          dataField: 'edit',
          text: 'Edit',
          editable: false,
          formatter: (cell, row, rowIndex) => {
            return (
              <>
                <Input
                  type="checkbox"
                  disabled
                  defaultChecked={safeDeepGet(row, ['edit'], 'off') === 'on'}
                />
              </>
            );
          },
        },
      ],
      RoleListColumns: [
        {
          text: 'id',
          dataField: 'id',
          sort: true,
          hidden: true,
          formatter: (cellContent, row) => <>{row.id}</>,
        },
        {
          text: 'Name',
          dataField: 'name',
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <h5 className="font-size-14 mb-1">{row.name}</h5>
            </>
          ),
        },
        {
          dataField: 'id',
          text: 'Role ID',
          formatter: (cellContent, row) => (
            <>
              <p className="font-size-14 mb-1">{row.id}</p>
            </>
          ),
        },
        {
          dataField: 'bussinessPoint',
          text: 'Business Point',
          formatter: (cellContent, row) => (
            <>
              <Button
                className={getButtonTheme()}
                // onClick={this.handleDetailBp}
                onClick={() => this.handleDetailBp(row)}
              >
                Detail
              </Button>
            </>
          ),
        },
        {
          dataField: 'navigation',
          text: 'Navigation',
          formatter: (cellContent, row) => (
            <>
              <Button
                className={getButtonTheme()}
                // onClick={this.handleDetailNav}
                onClick={() => this.handleDetailNav(row)}
              >
                Detail
              </Button>
            </>
          ),
        },
        {
          dataField: 'action',
          isDummyField: true,
          editable: false,
          text: 'Action',
          formatter: (cellContent, Role) => (
            <>
              {Role.id == 'super_admin' ? (
                <></>
              ) : (
                <div className="d-flex gap-3">
                  <Tippy content={'Edit'}>
                    <Link className="text-success" to="#">
                      <i
                        className="mdi mdi-pencil font-size-18"
                        id="edittooltip"
                        onClick={() => this.handleEditClick(Role)}
                      ></i>
                    </Link>
                  </Tippy>
                  {guest == 'off' ? (
                    <Tippy content={'Delete'}>
                      <Link className="text-danger" to="#">
                        <i
                          className="mdi mdi-delete font-size-18"
                          id="deletetooltip"
                          onClick={() => this.handleDeleteConfirm(Role)}
                        ></i>
                      </Link>
                    </Tippy>
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </>
          ),
        },
      ],
    };
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);

    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleNav = this.toggleNav.bind(this);
    this.handleDetailBp = this.handleDetailBp.bind(this);
    this.handleDetailNav = this.handleDetailNav.bind(this);
    this.showToast = this.showToast.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }
  toggleNav() {
    this.setState(prevState => ({
      modalNav: !prevState.modalNav,
    }));
  }

  componentDidMount() {
    // Load business point
    this.props.LoadRoleList();
    if (edit == 'off') {
      this.state.RoleListColumns.pop();
    }
  }
  handleValidSubmit = (e, values) => {
    const { UpdateRole } = this.props;
    const { Role } = this.state;
    const data = {
      id: Role.id,
      name: values.name,
      code: values.code,
      telegram_group: values.telegram_group,
    };
    UpdateRole(data);
    this.props.LoadRoleList();
    this.toggle();
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.deleteResult !== prevProps.deleteResult) {
      const code = safeDeepGet(
        this.props,
        ['deleteResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code == Result.SUCCESS) {
        this.props.LoadRoleList();
        this.showToast();
        this.setState({
          confirm_alert: false,
          success_dlg: false,
        });
      }
    }
  };
  /* Handle click */
  handleAddClick = arg => {
    this.props.history.push('/role/create');
  };
  handleDetailBp = row => {
    const bp = safeDeepGet(row, 'businessPoint', []);
    const name = safeDeepGet(row, 'name', []);
    this.setState({ bp, name });
    this.toggle();
  };
  handleDetailNav = row => {
    const nav = safeDeepGet(row, 'navigation', []);
    const name = safeDeepGet(row, 'name', []);

    this.setState({ nav, name });
    this.toggleNav();
  };

  handleEditClick = row => {
    const id = safeDeepGet(row, 'id', '');
    this.props.history.push('/role/edit/' + id);
  };
  handleDeleteClick = row => {
    this.props.DeleteRole(row);
  };
  handleDeleteConfirm(row) {
    this.setState({
      confirm_alert: true,
      row,
    });
  }
  showToast() {
    var toastType = 'success';
    var message = 'Berhasil Delete Role';
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }

  /* Pagination */
  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  render() {
    // TODO Loading
    const code = safeDeepGet(
      this.props,
      ['result', 'code'],
      Result.UNKNOWN_ERROR
    );
    const list = safeDeepGet(this.props, ['result', 'body'], []);
    const { SearchBar } = Search;

    const pageOptions = {
      sizePerPage: 25,
      totalSize: list.length, // replace later with size(users),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: 'id', // if dataField is not match to any column you defined, it will be ignored.
        order: 'desc', // desc or asc
      },
    ];

    const selectRow = {
      mode: 'checkbox',
    };
    const { column, columnBp, bp, nav, name } = this.state;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{buildTitle('Role List')}</title>
          </MetaTags>
          {safeDeepGet(navbar, ['Management', 'view'], safeDeepGet(navbar, ['Account', 'view'], 'off')) == 'off' ? (
            <Pages403 />
          ) : (
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Role" breadcrumbItem="List" />
            {this.state.success_dlg ? (
              <SweetAlert
                info
                title={this.state.dynamic_title}
                onConfirm={() => {
                  setTimeout(() => {
                    this.props.LoadRoleList();
                  }, 2000);
                  this.setState({ success_dlg: false });
                }}
              >
                {this.state.dynamic_description}
              </SweetAlert>
            ) : null}
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={this.state.RoleListColumns}
                      data={list}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={this.state.RoleListColumns}
                          data={list}
                          search
                        >
                          {toolkitprops => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitprops.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="8">
                                  <div className="text-sm-end">
                                    <Button
                                      className={getButtonTheme()}
                                      onClick={this.handleAddClick}
                                    >
                                      <i className="mdi mdi-plus-circle-outline me-1" />
                                      Create New Role
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      {...toolkitprops.baseProps}
                                      {...paginationTableProps}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        'table align-middle table-nowrap table-hover'
                                      }
                                      bordered={false}
                                      striped={false}
                                      responsive
                                      ref={this.node}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                    <Modal
                      size="lg"
                      isOpen={this.state.modalNav}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggleNav} tag="h4">
                        {'Detail Access Navigation Role ' + name}
                      </ModalHeader>
                      <ModalBody>
                        <BootstrapTable
                          keyField="id"
                          bootstrap4={true}
                          data={nav}
                          columns={column}
                          bordered={false}
                          ref={this.navRef}
                        />
                      </ModalBody>
                    </Modal>
                    <Modal
                      size="lg"
                      isOpen={this.state.modal}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.toggle} tag="h4">
                        {'Detail Access Business Point Role ' + name}
                      </ModalHeader>
                      <ModalBody>
                        <BootstrapTable
                          keyField="id"
                          bootstrap4={true}
                          data={bp}
                          columns={columnBp}
                          bordered={false}
                          ref={this.navRef}
                        />
                      </ModalBody>
                    </Modal>

                    {this.state.confirm_alert ? (
                      <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmButtonText="Yes, delete it!"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                          this.setState({
                            success_dlg: true,
                            dynamic_title: 'Deleted is Confirm',
                            dynamic_description:
                              'Wait Deleted is in Proggress.',
                            confirm_alert: false,
                          }),
                            this.handleDeleteClick(this.state.row);
                        }}
                        onCancel={() =>
                          this.setState({
                            confirm_alert: false,
                          })
                        }
                      >
                        You won&apos;t be able to revert this!
                      </SweetAlert>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  result: safeDeepGet(state, ['role', 'list'], {}),
  loading: safeDeepGet(state, ['role', 'loading'], true),
  updateResult: safeDeepGet(state, ['role', 'update'], {}),
  deleteResult: safeDeepGet(state, ['role', 'delete'], {}),
});

const mapDispatchToProps = dispatch => ({
  LoadRoleList: () => dispatch(list()),
  UpdateRole: payload => dispatch(update(payload)),
  DeleteRole: payload => dispatch(delete_(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleList);
