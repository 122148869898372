import React from 'react'
import { Redirect } from 'react-router-dom'

//Business Point Pages
import BusinessPointList from '../../Base/pages/BusinessPointNoRole'
import BusinessPointInfo from '../../Base/pages/BusinessPointNoRole/info'
import BusinessPointPlanogram from '../../Base/pages/BusinessPointNoRole/planogram_milano'
import BusinessPointInfoPlanogram from '../../Base/pages/BusinessPointNoRole/infoPlanogram'
import BusinessPointCalibration from '../pages/BusinessPoint/Calibrations'
import BusinessPointStock from '../pages/Stock/stockBusinessPoint'

import NestleProduct from '../../SvNestleMilano/pages/Product'
import NestleProductInfo from '../../SvNestleMilano/pages/Product/ingredients'
import NestleProductCreate from '../../SvNestleMilano/pages/Product/info'
import HistoryPlanogram from "../../Base/pages/BusinessPointNoRole/historyPlanogram"
import RefundList from '../../Base/pages/TransactionNoRole/Transaction-refund/index'

import NestleIngredient from '../../SvNestleMilano/pages/Ingredient'
import NestleIngredientCreate from '../../SvNestleMilano/pages/Ingredient/create'

import PayoutRedirect from '../pages/Payout/redirect'
// Transaction Pages
import TransactionList from '../../Base/pages/TransactionNoRole/index'
import TransactionReport from '../../Base/pages/TransactionNoRole/Transaction-report'
import SnapshotReport from '../pages/Transactions/snapshot'
import SnapshotDistributor from '../pages/Transactions/snapshot-distributor'
import SnapshotRefiller from '../pages/Transactions/snapshot-refiller'
import DetailTransactions from '../../Base/pages/TransactionNoRole/detail'

// Transaction Pages
import StockBusinessPoint from '../../SvNestleMilano/pages/Stock/BusinessPoints'
import StockWarehouse from '../../SvNestleMilano/pages/Stock/Warehouse'
import StockDeliveryOrder from '../../SvNestleMilano/pages/Stock/DeliveryOrder'

// Dashboard
import DashboardNestle from '../pages/Dashboard/index'

import UserProfile from '../../Base/pages/Authentication/UserProfile'

import AlertList from '../../SvNestleMilano/pages/Alert/index'
import AlertDeviceList from '../../SvNestleMilano/pages/Alert/alertdevice'

import NestleRefiller from '../../SvNestleMilano/pages/Refiller/index'
import NestleCreateRefiller from '../../SvNestleMilano/pages/Refiller/create'
import NestleDistributor from '../../SvNestleMilano/pages/Distributor/index'
import NestleCreateDistributor from '../../SvNestleMilano/pages/Distributor/create'
//Payout
import BankAccount from "../pages/Payout/bankAccount"
import PayoutReport from "../pages/Payout/index"
import InfoBank from "../pages/Payout/info"
import couponList from "../pages/Coupon/index"
import CsV from '../pages/Csv/csv'
let role = ''
const obj = JSON.parse(localStorage.getItem('role'))
if (obj != null) {
  role = obj.role
}
const svNestleRoutes = [
  
  // Nestle routes
  { path: '/dashboard', component: DashboardNestle },
  { path: '/csv', component: CsV },
  // Payout
  { path: "/payout/list", component: PayoutReport },
  { path: "/payout/bankaccount", component: BankAccount },
  { path: "/payout/create/bankaccount", component: InfoBank },
  { path: "/payout/edit/:id", component: InfoBank },
  { path: "/coupon/list", component: couponList },
  // profile
  { path: '/profile', component: UserProfile },
  /* Business point */
  { path: '/business-point/list', component: BusinessPointList },
  { path: '/business-point/create', component: BusinessPointInfo },
  { path: '/business-point/info/:id', component: BusinessPointInfo },
  { path: '/business-point/planogram/:id', component: BusinessPointPlanogram },
  { path: '/business-point/edit/:id', component: BusinessPointInfoPlanogram },
  { path: '/business-point/alert/:id', component: AlertDeviceList },
  { path: '/business-point/options/:id', component: BusinessPointCalibration },
  { path: '/business-point/stock/:id', component: BusinessPointStock },
  { path: "/business-point/history/planogram", component: HistoryPlanogram },

  // product
  { path: '/product/list', component: NestleProduct },
  { path: '/product/ingredient', component: NestleProductInfo },
  { path: '/product/create', component: NestleProductCreate },
  { path: '/product/edit/:id', component: NestleProductCreate },

  { path: '/ingredient/list', component: NestleIngredient },
  { path: '/ingredient/create', component: NestleIngredientCreate },
  { path: '/ingredient/edit/:id', component: NestleIngredientCreate },

  // transaction
  { path: '/transaction/list', component: TransactionList },
  { path: '/transaction/detail/:id', component: DetailTransactions },
  { path: '/transaction/report', component: TransactionReport },
  { path: '/transaction/snapshot', component: SnapshotReport },
  { path: '/snapshot/distributor', component: SnapshotDistributor },
  { path: '/snapshot/refiller', component: SnapshotRefiller },

  //Refiller
  { path: '/refill/list', component: NestleRefiller },
  { path: '/refill/create', component: NestleCreateRefiller },
  { path: '/refill/edit/:id', component: NestleCreateRefiller },
  { path: "/refund/list", component: RefundList },
  //Distributor
  { path: '/distributor/list', component: NestleDistributor },
  { path: '/distributor/create', component: NestleCreateDistributor },
  { path: '/distributor/edit/:id', component: NestleCreateDistributor },

  { path: '/alert/machine/list', component: AlertList },
  { path: '/alert/list', component: AlertList },

  // stock
  { path: '/stock/business-point', component: StockBusinessPoint },
  { path: '/stock/warehouse', component: StockWarehouse },
  { path: '/stock/create-do', component: StockDeliveryOrder },
  // eslint-disable-next-line react/display-name
  {
    path: '/',
    exact: true,
    component: () =>
      role == 'distributor' ? (
        <Redirect to="/business-point/list" />
      ) : role == 'refill' ? (
        <Redirect to="/business-point/list" />
      ) : (
        <Redirect to="/dashboard" />
      ),
  },
]

export { svNestleRoutes }
