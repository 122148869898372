/* eslint-disable semi */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  // eslint-disable-next-line no-unused-vars
  Button,
} from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';

import { APPLICATION_ID } from 'Apps';
//i18n
import { withTranslation } from 'react-i18next';
import {
  apiError,
  loginUser,
  socialLogin,
} from '../../../store/auth/login/actions';
// users
import user1 from '../../../assets/images/users/user1.png';

import { connect } from 'react-redux';

const getUserName = () => {
  if (localStorage.getItem('authUser')) {
    const obj = JSON.parse(localStorage.getItem('authUser'));
    return obj;
  }
};

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      name: 'Admin',
    };
    this.toggle = this.toggle.bind(this);
    this.handleReload = this.handleReload.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }));
  }

  componentDidMount() {
    const userData = getUserName();
    if (userData) {
      this.setState({ name: userData.username });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.success !== this.props.success) {
      const userData = getUserName();
      if (userData) {
        this.setState({ name: userData.username });
      }
    }
  }
  handleReload() {
    window.location.reload();

    const role = localStorage.getItem('role');
    let appId = '';
    if (APPLICATION_ID == '1000000211') {
      if (role == 'admin' || role == null) {
        localStorage.setItem('PROJECT', 'Nestle');
        localStorage.setItem('APP_ID', '1000000159');
        localStorage.removeItem('authUser');
        localStorage.removeItem('role');
      } else {
        localStorage.setItem('PROJECT', 'Nestle');
        localStorage.setItem('APP_ID', '1000000159');
        localStorage.removeItem('authUser');
        localStorage.removeItem('role');
      }
      appId = '1000000159';
    } else if (APPLICATION_ID == '1000000159') {
      if (role == 'admin' || role == null) {
        localStorage.setItem('PROJECT', 'Franke');
        localStorage.setItem('APP_ID', '1000000211');
        localStorage.removeItem('authUser');
        localStorage.removeItem('role');
      } else {
        localStorage.setItem('PROJECT', 'Franke');
        localStorage.setItem('APP_ID', '1000000211');
        localStorage.removeItem('authUser');
        localStorage.removeItem('role');
      }
      appId = '1000000211';
    }

    const pp = atob(localStorage.getItem('pp'));
    const email = localStorage.getItem('email');
    const values = {
      email: email,
      password: pp,
      appId: appId
    };
    // console.log(values)
    this.props.loginUser(values, this.props.history);
  }

  render() {
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item"
            id="page-header-user-dropdown"
            tag="button"
          >
            <img
              className="rounded-circle header-profile-user"
              src={user1}
              alt="Header Avatar"
            />{' '}
            <span className="d-none d-xl-inline-block ms-1">
              {this.state.name}
            </span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <Link tag="a" to="/profile" className="dropdown-item">
              <i className="bx bx-user font-size-16 align-middle me-1" />
              {this.props.t('Profile')}
            </Link>
            {APPLICATION_ID == '1000000159' ? (
              <Link
                to="#"
                className="dropdown-item"
                onClick={this.handleReload}
              >
                <i className="bx bx-message-check font-size-16 align-middle me-1" />
                <span>{this.props.t('Switch to Franke')}</span>
              </Link>
            ) : APPLICATION_ID == '1000000211' ? (
              <Link
                to="#"
                className="dropdown-item"
                onClick={this.handleReload}
              >
                <i className="bx bx-message-check font-size-16 align-middle me-1" />
                <span>{this.props.t('Switch to Milano')}</span>
              </Link>
            ) : (
              '  '
            )}

            <div className="dropdown-divider" />
            <Link to="/logout" className="dropdown-item">
              <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
              <span>{this.props.t('Logout')}</span>
            </Link>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

ProfileMenu.propTypes = {
  t: PropTypes.any,
  success: PropTypes.string,
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
};

const mapStateToProps = state => {
  const { success } = state.Profile;
  const { error } = state.Login;
  return { success, error };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(
    withTranslation()(ProfileMenu)
  )
);
